export type SubscriptionTier = 'free' | 'premium' | 'preferred';

export interface SubscriptionFeature {
  name: string;
  included: boolean;
  details?: string;
}

export interface PricingOption {
  interval: 'monthly' | 'yearly';
  price: number;
  currency: string;
  savings?: number;
}

export interface SubscriptionPlan {
  tier: SubscriptionTier;
  name: string;
  description: string;
  features: SubscriptionFeature[];
  pricing: PricingOption[];
  recommended?: boolean;
}

export const SUBSCRIPTION_PLANS: SubscriptionPlan[] = [
  {
    tier: 'free',
    name: 'Basic Access',
    description: 'Perfect for casual users and occasional sellers',
    features: [
      { name: 'Create Basic YardSale Listings', included: true },
      { name: 'Search for YardSales', included: true },
      { name: 'Limited Notifications', included: true },
      { name: 'Search YardSales by Categories', included: false },
      { name: 'Ad-supported experience', included: true },
      { name: 'Interactive Map Filtering', included: false },
      { name: 'Advanced Analytics', included: false },
      { name: 'Custom branding', included: false },
    ],
    pricing: [
      { interval: 'monthly', price: 0, currency: 'USD' }
    ]
  },
  {
    tier: 'premium',
    name: 'Premium',
    description: 'Enhanced features for frequent buyers and sellers',
    recommended: true,
    features: [
      { name: 'Unlimited Listings with Advanced Detail', included: true },
      { name: 'Unlimited Photos', included: true },
      { name: 'Advanced Search Filters', included: true, details: 'Including category filters' },
      { name: 'Ad-Free Experience', included: true },
      { name: 'Early Notifications', included: true },
	  { name: 'Interactive Map Filtering', included: true },
      { name: 'Priority Listing Placement', included: true },
      { name: 'Custom Branding', included: false },
    ],
    pricing: [
      { interval: 'monthly', price: 7.99, currency: 'USD' },
      { interval: 'yearly', price: 77.00, currency: 'USD', savings: 20 }
    ]
  },
  {
    tier: 'preferred',
    name: 'Preferred Seller',
    description: 'Full-spectrum tools for serious sellers',
    features: [
      { name: 'Unlimited Listings and Photos', included: true },
      { name: 'Advanced Listing Placement', included: true },
	  { name: 'Easily Re-List YardSales', included: true },
      { name: 'Inventory Management', included: true },
      { name: 'YardSales Analytics', included: true },
      { name: 'Direct Messaging', included: true },
      { name: 'Bulk Uploads', included: true },
      { name: 'Priority Support', included: true },
      { name: 'Custom Branding', included: true },
    ],
    pricing: [
      { interval: 'monthly', price: 14.99, currency: 'USD' },
      { interval: 'yearly', price: 99.99, currency: 'USD', savings: 45 }
    ]
  }
];

export interface AddOn {
  id: string;
  name: string;
  description: string;
  price: number;
  currency: string;
}

export const ADD_ONS: AddOn[] = [
  {
    id: 'listing-boost',
    name: 'Listing Boost',
    description: 'Boost your Listing Visibility for 24 Hours',
    price: 3.99,
    currency: 'USD'
  },
  {
    id: 'enhanced-analytics',
    name: 'Enhanced Analytics',
    description: 'Detailed Analytics for a Single Listing.',
    price: 3.99,
    currency: 'USD'
  },
  {
    id: 'unlock-categories',
    name: 'Search by Item Categories',
    description: 'Unlock the Ability to Search all YardSales and Filter by Category (e.g. Antiques, Furniture, Vintage Clothing) ',
    price: 2.99,
    currency: 'USD'
  },
  {
    id: 'extra-photos',
    name: 'Extra Photos Package',
    description: 'Includew 10 Additional Photos in a Basic Listing',
    price: 1.99,
    currency: 'USD'
  }

];
