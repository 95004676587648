import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Stack,
  Chip,
  Divider,
  CircularProgress,
  Alert,
  useTheme,
  useMediaQuery,
  Skeleton,
  IconButton
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ShareIcon from '@mui/icons-material/Share';
import FlagIcon from '@mui/icons-material/Flag';
import StarIcon from '@mui/icons-material/Star';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { auth, getYardSale, getUserProfile, getReviewsForSale } from '../services/firebase';
import { UserSale, UserProfile } from '../types/user';
import { Review } from '../types/social';
import ShareButtons from '../components/social/ShareButtons';
import ReportDialog from '../components/ReportDialog';
import RatingDialog from '../components/social/RatingDialog';
import SellerProfile from '../components/social/SellerProfile';
import ReservationList from '../components/reservation/ReservationList';
import ReservationDialog from '../components/reservation/ReservationDialog';
import ReviewStars from '../components/social/ReviewStars';
import { ReservableItem } from '../types/reservation';
import { useAuthState } from 'react-firebase-hooks/auth';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { deleteYardSale } from '../services/firebase';

const YardSaleDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [user] = useAuthState(auth);
  const [sale, setSale] = useState<UserSale | null>(null);
  const [seller, setSeller] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showShareButtons, setShowShareButtons] = useState(false);
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [showRatingDialog, setShowRatingDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ReservableItem | null>(null);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  const [timeLeft, setTimeLeft] = useState<string>('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    const fetchSaleDetails = async () => {
      if (!id) {
        navigate('/');
        return;
      }

      try {
        setLoading(true);
        setError('');

        const [saleData, reviewsData] = await Promise.all([
          getYardSale(id),
          getReviewsForSale(id)
        ]);

        if (!saleData) {
          setError('Sale not found');
          return;
        }

        setSale(saleData);
        setReviews(reviewsData);

        // Fetch seller profile
        const sellerData = await getUserProfile(saleData.userId);
        if (sellerData) {
          setSeller(sellerData);
        }

        // Check if sale is saved by current user
        if (user && sellerData?.savedSales) {
          setIsSaved(sellerData.savedSales.includes(id));
        }
      } catch (err) {
        console.error('Error fetching sale details:', err);
        setError('Failed to load sale details');
      } finally {
        setLoading(false);
      }
    };

    fetchSaleDetails();
  }, [id, navigate, user]);


  // Update countdown timer
  useEffect(() => {
    if (!sale) return;
  
    const updateTimeLeft = () => {
      const now = new Date();
      const saleDate = new Date(sale.date + 'T' + sale.time.start);
      const endDate = new Date(sale.date + 'T' + sale.time.end);
      
      // Check if sale has ended
      if (now > endDate) {
        setTimeLeft('🔴 This Yard Sale has Ended');
        return;
      }
  
      // Check if sale is in progress
      if (now >= saleDate && now <= endDate) {
        setTimeLeft('🟢 The Yard Sale is Happening Now!');
        return;
      }
  
      // Calculate time until sale starts
      const diff = saleDate.getTime() - now.getTime();
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  
      setTimeLeft(`⏰ Starts in ${days}d ${hours}h ${minutes}m`);
    };
  
    updateTimeLeft();
    const interval = setInterval(updateTimeLeft, 60000); // Update every minute
  
    return () => clearInterval(interval);
  }, [sale]);

  const isOwner = user && sale ? user.uid === sale.userId : false;

  const handleReserve = (itemId: string) => {
    // TODO: Implement reservation functionality
    setSelectedItem(null);
  };

  const handleReservationSubmit = (message: string) => {
    // TODO: Implement reservation submission
    setSelectedItem(null);
  };

  const handleApproveReservation = (reservationId: string) => {
    // TODO: Implement reservation approval
  };

  const handleDenyReservation = (reservationId: string) => {
    // TODO: Implement reservation denial
  };

  const handleRated = () => {
    // Refresh reviews after new rating
    if (id) {
      getReviewsForSale(id).then(setReviews);
    }
  };

  const handleSaveToggle = async () => {
    // TODO: Implement save functionality
    setIsSaved(!isSaved);
  };

  const handleNextImage = () => {
    if (sale && sale.images.length > 0) {
      setCurrentImageIndex((prev) => 
        prev === sale.images.length - 1 ? 0 : prev + 1
      );
    }
  };

  const handlePrevImage = () => {
    if (sale && sale.images.length > 0) {
      setCurrentImageIndex((prev) => 
        prev === 0 ? sale.images.length - 1 : prev - 1
      );
    }
  };

  const handleDelete = async () => {
    if (!user || !sale) return;
    
    try {
      setLoading(true);
      await deleteYardSale(sale.id, user.uid);
      navigate('/profile'); // Redirect to profile after deletion
    } catch (error) {
      console.error('Error deleting sale:', error);
      setError('Failed to delete sale');
    } finally {
      setLoading(false);
      setShowDeleteDialog(false);
    }
  };

  if (loading) {
    return (
      <Container sx={{ py: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
              <Skeleton variant="rectangular" height={400} />
              <Paper sx={{ p: 3 }}>
                <Stack spacing={2}>
                  <Skeleton variant="text" width="60%" height={40} />
                  <Skeleton variant="text" width="40%" />
                  <Skeleton variant="text" width="30%" />
                  <Skeleton variant="rectangular" height={100} />
                </Stack>
              </Paper>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (error || !sale || !seller) {
    return (
      <Container sx={{ py: 4 }}>
        <Alert severity="error">{error || 'Sale not found'}</Alert>
      </Container>
    );
  }

  const averageRating = reviews.length > 0
    ? reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length
    : 0;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Stack spacing={3}>
            {/* Image Gallery */}
            {sale.images.length > 0 && (
              <Paper 
                sx={{ 
                  position: 'relative',
                  height: isMobile ? 300 : 400,
                  overflow: 'hidden'
                }}
              >
                <Box
                  component="img"
                  src={sale.images[currentImageIndex]}
                  alt={`Sale image ${currentImageIndex + 1}`}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
                {sale.images.length > 1 && (
                  <>
                    <IconButton
                      onClick={handlePrevImage}
                      sx={{
                        position: 'absolute',
                        left: 8,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        bgcolor: 'rgba(255, 255, 255, 0.8)',
                        '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' }
                      }}
                    >
                      <NavigateBeforeIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleNextImage}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        bgcolor: 'rgba(255, 255, 255, 0.8)',
                        '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' }
                      }}
                    >
                      <NavigateNextIcon />
                    </IconButton>
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 8,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        bgcolor: 'rgba(0, 0, 0, 0.6)',
                        color: 'white',
                        px: 1,
                        borderRadius: 1,
                        fontSize: 14
                      }}
                    >
                      {currentImageIndex + 1} / {sale.images.length}
                    </Box>
                  </>
                )}
              </Paper>
            )}

            {/* Header */}
            <Paper sx={{ p: 3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    {sale.title}
                    {sale.featured && (
                      <Chip
                        label="Featured"
                        color="primary"
                        size="small"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Typography>
                  <Typography variant="h6" color="primary.main" gutterBottom>
                    {timeLeft}
                  </Typography>
                </Box>
                <Box>
                  <IconButton onClick={handleSaveToggle} sx={{ mr: 1 }}>
                    {isSaved ? <BookmarkIcon color="primary" /> : <BookmarkBorderIcon />}
                  </IconButton>
                  <Button
                    startIcon={<ShareIcon />}
                    onClick={() => setShowShareButtons(true)}
                    sx={{ mr: 1 }}
                  >
                    Share
                  </Button>
                  {!isOwner && user && (
                    <Button
                      startIcon={<StarIcon />}
                      onClick={() => setShowRatingDialog(true)}
                      sx={{ mr: 1 }}
                    >
                      Rate
                    </Button>
                  )}
                  {!isOwner && (
                    <Button
                      startIcon={<FlagIcon />}
                      onClick={() => setShowReportDialog(true)}
                    >
                      Report
                    </Button>
                  )}
                  {isOwner && (
                    <Button
                      startIcon={<DeleteIcon />}
                      color="error"
                      onClick={() => setShowDeleteDialog(true)}
                    >
                      Delete
                    </Button>
                  )}
                </Box>
              </Box>

              <Stack spacing={2}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <LocationOnIcon color="action" />
                  <Typography>{sale.location}</Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CalendarTodayIcon color="action" />
                  <Typography>
                    {new Date(sale.date).toLocaleDateString()} • {sale.time.start} - {sale.time.end}
                  </Typography>
                </Box>

                <Box>
                  <ReviewStars 
                    value={averageRating}
                    showCount
                    count={reviews.length}
                    size="small"
                  />
                </Box>

                <Typography variant="body1">
                  {sale.description}
                </Typography>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {sale.categories.map((category: string) => (
                    <Chip key={category} label={category} />
                  ))}
                </Box>
              </Stack>
            </Paper>

            {/* Reservable Items */}
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Items Available for Reservation
              </Typography>
              <ReservationList
                items={[]}
                reservations={[]} // TODO: Implement reservations
                onApprove={handleApproveReservation}
                onDeny={handleDenyReservation}
                onReserve={handleReserve}
                isOwner={isOwner}
              />
            </Paper>
          </Stack>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            {/* Seller Profile */}
            <Paper sx={{ p: 3 }}>
              <SellerProfile 
                seller={{
                  id: seller.id,
                  name: `${seller.firstName} ${seller.lastName.charAt(0)}.`,
                  isVerified: seller.isVerified
                }}
                rating={seller.stats.averageRating}
                reviewCount={seller.stats.reviewCount}
              />
            </Paper>

            {/* Stats */}
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Stats
              </Typography>
              <Stack spacing={2}>
                <Box>
                  <Typography color="text.secondary" variant="body2">
                    Views
                  </Typography>
                  <Typography variant="h6">
                    {sale.stats.views}
                  </Typography>
                </Box>
                <Divider />
                <Box>
                  <Typography color="text.secondary" variant="body2">
                    Saves
                  </Typography>
                  <Typography variant="h6">
                    {sale.stats.saves}
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Stack>
        </Grid>
      </Grid>

      {/* Dialogs */}
      <ShareButtons
        open={showShareButtons}
        onClose={() => setShowShareButtons(false)}
        url={window.location.href}
        title={sale.title}
      />

      <ReportDialog
        open={showReportDialog}
        onClose={() => setShowReportDialog(false)}
        saleId={sale.id}
      />

      {user && (
        <RatingDialog
          open={showRatingDialog}
          onClose={() => setShowRatingDialog(false)}
          saleId={sale.id}
          userId={user.uid}
          onRated={handleRated}
        />
      )}

      {selectedItem && (
        <ReservationDialog
          open={!!selectedItem}
          onClose={() => setSelectedItem(null)}
          item={selectedItem}
          onSubmit={handleReservationSubmit}
        />
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>Delete Yard Sale</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this yard sale? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowDeleteDialog(false)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDelete}
            color="error"
            disabled={loading}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default YardSaleDetailsPage;
