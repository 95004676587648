import React from 'react';
import {
  Box,
  Typography,
  Chip,
  Stack,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { CATEGORIES } from '../../config/constants';

interface MapPageFiltersProps {
  categories: string[];
  onCategoryChange: (categories: string[]) => void;
  onReset: () => void;
}

const MapPageFilters: React.FC<MapPageFiltersProps> = ({
  categories,
  onCategoryChange,
  onReset
}) => {
  const handleCategoryToggle = (category: string) => {
    const newCategories = categories.includes(category)
      ? categories.filter(c => c !== category)
      : [...categories, category];
    
    onCategoryChange(newCategories);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">
          Filters
        </Typography>
        <Button
          startIcon={<RestartAltIcon />}
          onClick={onReset}
          size="small"
        >
          Reset
        </Button>
      </Box>

      <Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="categories-content"
          id="categories-header"
        >
          <Typography>
            Categories {categories.length > 0 && `(${categories.length})`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
            {CATEGORIES.map(category => (
              <Chip
                key={category}
                label={category}
                onClick={() => handleCategoryToggle(category)}
                color={categories.includes(category) ? 'primary' : 'default'}
                sx={{ mb: 1 }}
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default MapPageFilters;