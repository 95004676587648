import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { addDays, startOfToday, endOfWeek, startOfWeek } from 'date-fns';

interface DateRangePresetsProps {
  onSelect: (startDate: Date, endDate: Date) => void;
}

const DateRangePresets: React.FC<DateRangePresetsProps> = ({ onSelect }) => {
  const today = startOfToday();

  const presets = [
    {
      label: 'This Weekend',
      getRange: () => {
        const start = startOfWeek(addDays(today, 5), { weekStartsOn: 6 }); // Next Saturday
        const end = endOfWeek(start, { weekStartsOn: 6 }); // Next Sunday
        return { start, end };
      }
    },
    {
      label: 'Next Weekend',
      getRange: () => {
        const start = startOfWeek(addDays(today, 12), { weekStartsOn: 6 }); // Saturday after next
        const end = endOfWeek(start, { weekStartsOn: 6 }); // Sunday after next
        return { start, end };
      }
    },
    {
      label: 'Next 7 Days',
      getRange: () => ({
        start: today,
        end: addDays(today, 6)
      })
    },
    {
      label: 'Next 30 Days',
      getRange: () => ({
        start: today,
        end: addDays(today, 29)
      })
    }
  ];

  return (
    <Stack spacing={1}>
      <Typography variant="caption" color="text.secondary">
        Quick Select
      </Typography>
      <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
        {presets.map(({ label, getRange }) => (
          <Button
            key={label}
            size="small"
            variant="outlined"
            onClick={() => {
              const { start, end } = getRange();
              onSelect(start, end);
            }}
            sx={{
              textTransform: 'none',
              fontSize: '0.75rem',
              py: 0.5,
              minWidth: 'auto',
              flex: '1 0 calc(50% - 4px)' // 2 buttons per row with 8px gap
            }}
          >
            {label}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
};

export default DateRangePresets;
