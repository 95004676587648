import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { app } from './firebase';

const storage = getStorage(app);

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/webp'];

interface ValidationResult {
  valid: boolean;
  error?: string;
}

// Validate a single file
const validateFile = (file: File): ValidationResult => {
  if (!ALLOWED_TYPES.includes(file.type)) {
    return {
      valid: false,
      error: `Invalid file type. Only ${ALLOWED_TYPES.map(type => type.split('/')[1]).join(', ')} are allowed.`
    };
  }

  if (file.size > MAX_FILE_SIZE) {
    return {
      valid: false,
      error: `File size too large. Maximum size is 5MB.`
    };
  }

  return { valid: true };
};

// Helper function to generate a unique file path
const generateFilePath = (userId: string, file: File): string => {
  const timestamp = Date.now();
  const randomString = Math.random().toString(36).substring(2, 15);
  const safeFileName = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
  return `sales/${userId}/${timestamp}_${randomString}_${safeFileName}`;
};

// Upload a single image
export const uploadImage = async (userId: string, file: File): Promise<string> => {
  // Validate file before upload
  const validation = validateFile(file);
  if (!validation.valid) {
    throw new Error(validation.error);
  }

  try {
    const filePath = generateFilePath(userId, file);
    const storageRef = ref(storage, filePath);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to upload image: ${error.message}`);
    }
    throw new Error('Failed to upload image');
  }
};

// Upload multiple images
export const uploadImages = async (userId: string, files: File[]): Promise<string[]> => {
  // Validate all files first
  const validations = files.map(file => ({
    file,
    validation: validateFile(file)
  }));

  const invalidFile = validations.find(v => !v.validation.valid);
  if (invalidFile) {
    throw new Error(invalidFile.validation.error);
  }

  try {
    const uploadPromises = files.map(file => uploadImage(userId, file));
    return await Promise.all(uploadPromises);
  } catch (error) {
    console.error('Error uploading images:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to upload images: ${error.message}`);
    }
    throw new Error('Failed to upload images');
  }
};

// Delete a single image by URL
export const deleteImage = async (imageUrl: string): Promise<void> => {
  try {
    const imageRef = ref(storage, imageUrl);
    await deleteObject(imageRef);
  } catch (error) {
    console.error('Error deleting image:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to delete image: ${error.message}`);
    }
    throw new Error('Failed to delete image');
  }
};

// Delete multiple images by URLs
export const deleteImages = async (imageUrls: string[]): Promise<void> => {
  try {
    const deletePromises = imageUrls.map(url => deleteImage(url));
    await Promise.all(deletePromises);
  } catch (error) {
    console.error('Error deleting images:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to delete images: ${error.message}`);
    }
    throw new Error('Failed to delete images');
  }
};

// Export constants for use in components
export const IMAGE_UPLOAD_CONFIG = {
  maxFileSize: MAX_FILE_SIZE,
  allowedTypes: ALLOWED_TYPES,
  maxFiles: 10
};
