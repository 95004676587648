import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import mapboxgl, { Map, Marker, Popup } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './YardSaleMap.css';
import { UserSale } from '../../types/user';

// Set Mapbox access token from environment variable
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';

interface MapCenter {
  lat: number;
  lng: number;
}

interface YardSaleMapProps {
  center: MapCenter;
  sales: UserSale[];
  selectedSale: UserSale | null;
  onSaleSelect: (sale: UserSale) => void;
}

const YardSaleMap: React.FC<YardSaleMapProps> = ({
  center,
  sales,
  selectedSale,
  onSaleSelect
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<Map | null>(null);
  const markers = useRef<{ [key: string]: Marker }>({});
  const popup = useRef<Popup | null>(null);

  // Initialize map
  useEffect(() => {
    if (!mapContainer.current) return;
  
    // Clean up existing map if it exists
    if (map.current) {
      map.current.remove();
    }
  
    // Create new map with current center
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [center.lng, center.lat],
      zoom: 12
    });
  
    map.current.addControl(new mapboxgl.NavigationControl());
  
    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [center]);
  
  // Keep the separate flyTo effect for smooth transitions after initial load
  useEffect(() => {
    if (!map.current) return;
  
    map.current.flyTo({
      center: [center.lng, center.lat],
      zoom: map.current.getZoom(),
      duration: 1000
    });
  }, [center]);

  // Update markers when sales change
  useEffect(() => {
    if (!map.current) return;

    // Clear existing markers
    Object.values(markers.current).forEach(marker => marker.remove());
    markers.current = {};

    // Add new markers
    sales.forEach(sale => {
      if (!sale.coordinates) return;

      const el = document.createElement('div');
      el.className = `yard-sale-marker ${selectedSale?.id === sale.id ? 'selected' : ''}`;
      el.innerHTML = '$';

      const marker = new mapboxgl.Marker(el)
        .setLngLat([sale.coordinates.lng, sale.coordinates.lat])
        .addTo(map.current!);

      marker.getElement().addEventListener('click', () => {
        onSaleSelect(sale);
      });

      markers.current[sale.id] = marker;
    });
  }, [sales, selectedSale]);

  // Update popup when selected sale changes
  useEffect(() => {
    if (!map.current || !selectedSale || !selectedSale.coordinates) {
      popup.current?.remove();
      return;
    }

    const marker = markers.current[selectedSale.id];
    if (!marker) return;

    // Remove existing popup
    popup.current?.remove();

    // Create new popup
    popup.current = new mapboxgl.Popup({ offset: 25 })
  .setLngLat([selectedSale.coordinates.lng, selectedSale.coordinates.lat])
  .setHTML(`
    <div class="popup-content">
      <h3 class="popup-title">${selectedSale.title}</h3>
      <p class="popup-info">${selectedSale.date}</p>
      <p class="popup-info">${selectedSale.time.start} - ${selectedSale.time.end}</p>
      ${selectedSale.description ? `<p class="popup-info">${selectedSale.description}</p>` : ''}
      ${selectedSale.categories?.length ? `
        <div class="popup-categories">
          ${selectedSale.categories.map(category => 
            `<span class="popup-category">${category}</span>`
          ).join('')}
        </div>
      ` : ''}
      <a href="/sale/${selectedSale.id}" class="popup-link">View Details</a>
    </div>
  `)
      .addTo(map.current);

    // Pan to the selected sale
    map.current.flyTo({
      center: [selectedSale.coordinates.lng, selectedSale.coordinates.lat],
      zoom: 14,
      duration: 1000
    });
  }, [selectedSale]);

  return (
    <Box
      ref={mapContainer}
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }}
    />
  );
};

export default YardSaleMap;
