import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  Box,
  CircularProgress,
  Alert,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Paper,
  ClickAwayListener
} from '@mui/material';
import { geocodeAddress, getLocationSuggestions, LocationSuggestion } from '../services/mapbox';
import { Coordinates } from '../types/user';

interface LocationFieldProps {
  value: string;
  onChange: (value: string, coordinates?: Coordinates) => void;
  error?: string;
  required?: boolean;
}

const LocationField: React.FC<LocationFieldProps> = ({
  value,
  onChange,
  error,
  required = false
}) => {
  const [isGeocoding, setIsGeocoding] = useState(false);
  const [geocodeError, setGeocodeError] = useState<string | null>(null);
  const [suggestions, setSuggestions] = useState<LocationSuggestion[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [isValidFormat, setIsValidFormat] = useState(true);
  const [validationError, setValidationError] = useState<string | null>(null);

  const handleGeocoding = useCallback(async (address: string) => {
    if (!address.trim()) {
      onChange(address, undefined);
      setIsValidFormat(true);
      setValidationError(null);
      return;
    }

    setIsGeocoding(true);
    setGeocodeError(null);

    try {
      const result = await geocodeAddress(address);
      onChange(result.placeName, result.location);
      setGeocodeError(null);
      setIsValidFormat(true);
      setValidationError(null);
    } catch (error) {
      setGeocodeError('Unable to verify this address. Please check and try again.');
      onChange(address, undefined);
      setIsValidFormat(false);
      setValidationError('Please select a location from the suggestions');
    } finally {
      setIsGeocoding(false);
    }
  }, [onChange]);

  const fetchSuggestions = useCallback(async (query: string) => {
    if (query.length < 2) {
      setSuggestions([]);
      setIsValidFormat(true);
      setValidationError(null);
      return;
    }
    const results = await getLocationSuggestions(query);
    setSuggestions(results);
    setShowSuggestions(true);
    
    // Set validation state while typing
    setIsValidFormat(false);
    setValidationError('Please select a location from the suggestions');
  }, []);

  // Add validation effect
  useEffect(() => {
    if (!value) {
      setIsValidFormat(true);
      setValidationError(null);
      return;
    }

    // Validate format when value changes manually (not through suggestion selection)
    if (!value.includes(',')) {
      setIsValidFormat(false);
      setValidationError('Please select a location from the suggestions');
    }
  }, [value]);

  useEffect(() => {
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set new timeout for suggestions
    const newTimeoutId = setTimeout(() => {
      fetchSuggestions(value);
    }, 300);

    setTimeoutId(newTimeoutId);

    // Cleanup
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [value, fetchSuggestions]);

  const handleSuggestionClick = (suggestion: LocationSuggestion) => {
    onChange(suggestion.context, suggestion.location);
    setShowSuggestions(false);
    setSuggestions([]);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <TextField
        label="Search Locations"
        placeholder="Start Typing a City..."
        value={value}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        error={!!error || !!geocodeError}
        helperText={error || geocodeError}
        fullWidth
        onFocus={() => setShowSuggestions(true)}
        InputProps={{
          endAdornment: isGeocoding && (
            <CircularProgress
              size={20}
              sx={{ position: 'absolute', right: 10 }}
            />
          )
        }}
      />
      {showSuggestions && suggestions.length > 0 && (
        <ClickAwayListener onClickAway={() => setShowSuggestions(false)}>
          <Paper
            sx={{
              position: 'absolute',
              top: '100%',
              left: 0,
              right: 0,
              mt: 1,
              maxHeight: 300,
              overflow: 'auto',
              zIndex: 1000
            }}
          >
            <List>
              {suggestions.map((suggestion) => (
                <ListItem
                  key={suggestion.id}
                  button
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <ListItemText
                    primary={suggestion.placeName}
                    secondary={suggestion.context}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </ClickAwayListener>
      )}
      <Collapse in={!!geocodeError}>
        <Alert severity="error" sx={{ mt: 1 }}>
          {geocodeError}
        </Alert>
      </Collapse>
    </Box>
  );
};

export default LocationField;
