import React, { useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Grid,
  Paper,
  Button
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ReportIcon from '@mui/icons-material/Report';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';

import { auth, subscribeToMetrics } from '../../services/firebase';
import { DashboardMetrics } from '../../types/admin';
import { useNavigate } from 'react-router-dom';
import ReportsView from './ReportsView';

const DRAWER_WIDTH = 240;

type View = 'dashboard' | 'users' | 'sales' | 'reports';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [currentView, setCurrentView] = useState<View>('dashboard');
  const [metrics, setMetrics] = useState<DashboardMetrics>({
    totalUsers: 0,
    activeUsers: 0,
    activeSales: 0,
    totalSales: 0,
    totalReviews: 0,
    averageRating: 0,
    activeListings: 0,
    reportedListings: 0,
    premiumUsers: 0,
    preferredUsers: 0,
    totalRevenue: 0
  });

  useEffect(() => {
    const unsubscribe = subscribeToMetrics((newMetrics: DashboardMetrics) => {
      setMetrics(newMetrics);
    });

    return () => unsubscribe();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/admin/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const renderView = () => {
    switch (currentView) {
      case 'reports':
        return <ReportsView />;
      case 'dashboard':
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography color="text.secondary" gutterBottom>
                  Total Users
                </Typography>
                <Typography variant="h4">{metrics.totalUsers}</Typography>
                <Typography color="text.secondary" sx={{ flex: 1 }}>
                  Active: {metrics.activeUsers}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography color="text.secondary" gutterBottom>
                  Active Sales
                </Typography>
                <Typography variant="h4">{metrics.activeSales}</Typography>
                <Typography color="text.secondary" sx={{ flex: 1 }}>
                  Total: {metrics.totalSales}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography color="text.secondary" gutterBottom>
                  Premium Users
                </Typography>
                <Typography variant="h4">{metrics.premiumUsers}</Typography>
                <Typography color="text.secondary" sx={{ flex: 1 }}>
                  Preferred: {metrics.preferredUsers}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper 
                sx={{ 
                  p: 2, 
                  display: 'flex', 
                  flexDirection: 'column',
                  bgcolor: metrics.reportedListings > 0 ? 'error.light' : undefined
                }}
              >
                <Typography 
                  color={metrics.reportedListings > 0 ? 'error.contrastText' : 'text.secondary'} 
                  gutterBottom
                >
                  Reported Listings
                </Typography>
                <Typography 
                  variant="h4"
                  color={metrics.reportedListings > 0 ? 'error.contrastText' : undefined}
                >
                  {metrics.reportedListings}
                </Typography>
                <Button
                  size="small"
                  sx={{ mt: 1, alignSelf: 'flex-start' }}
                  onClick={() => setCurrentView('reports')}
                >
                  View Reports
                </Button>
              </Paper>
            </Grid>
          </Grid>
        );
      // Add other views as needed
      default:
        return <Typography>Coming Soon</Typography>;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          transition: (theme) =>
            theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          ...(open && {
            marginLeft: DRAWER_WIDTH,
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            transition: (theme) =>
              theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
          }),
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Admin Dashboard
          </Typography>
          <Button color="inherit" onClick={handleLogout} startIcon={<LogoutIcon />}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          ...(open && {
            ...{
              width: DRAWER_WIDTH,
              transition: (theme) =>
                theme.transitions.create('width', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              overflowX: 'hidden',
            },
          }),
          ...(!open && {
            transition: (theme) =>
              theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            overflowX: 'hidden',
            width: (theme) => theme.spacing(7),
          }),
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>
          <ListItem 
            button 
            onClick={() => setCurrentView('dashboard')}
            selected={currentView === 'dashboard'}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem 
            button 
            onClick={() => setCurrentView('users')}
            selected={currentView === 'users'}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem 
            button 
            onClick={() => setCurrentView('sales')}
            selected={currentView === 'sales'}
          >
            <ListItemIcon>
              <StorefrontIcon />
            </ListItemIcon>
            <ListItemText primary="Sales" />
          </ListItem>
          <ListItem 
            button 
            onClick={() => setCurrentView('reports')}
            selected={currentView === 'reports'}
          >
            <ListItemIcon>
              <ReportIcon color={metrics.reportedListings > 0 ? 'error' : undefined} />
            </ListItemIcon>
            <ListItemText 
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Reports
                  {metrics.reportedListings > 0 && (
                    <Paper
                      sx={{
                        ml: 1,
                        px: 1,
                        bgcolor: 'error.main',
                        color: 'error.contrastText',
                        borderRadius: '12px',
                        fontSize: '0.75rem'
                      }}
                    >
                      {metrics.reportedListings}
                    </Paper>
                  )}
                </Box>
              }
            />
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {renderView()}
        </Container>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
