import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// Set Mapbox access token
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';

export interface MapLocation {
  lat: number;
  lng: number;
}

export interface GeocodingResult {
  location: MapLocation;
  placeName: string;
  bbox?: [number, number, number, number];
}

export interface LocationSuggestion {
  id: string;
  placeName: string;
  location: MapLocation;
  context: string;
}

export const geocodeAddress = async (address: string): Promise<GeocodingResult> => {
  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxgl.accessToken}&country=US`
    );

    if (!response.ok) {
      throw new Error('Geocoding failed');
    }

    const data = await response.json();
    if (!data.features || data.features.length === 0) {
      throw new Error('No results found');
    }

    const feature = data.features[0];
    return {
      location: {
        lng: feature.center[0],
        lat: feature.center[1]
      },
      placeName: feature.place_name,
      bbox: feature.bbox
    };
  } catch (error) {
    console.error('Error geocoding address:', error);
    throw error;
  }
};

export const getLocationSuggestions = async (query: string): Promise<LocationSuggestion[]> => {
  if (!query.trim()) return [];

  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        query
      )}.json?access_token=${mapboxgl.accessToken}&country=US&types=place,postcode,address&autocomplete=true`
    );

    if (!response.ok) {
      throw new Error('Location suggestions failed');
    }

    const data = await response.json();
    if (!data.features) return [];

    return data.features.map((feature: any) => ({
      id: feature.id,
      placeName: feature.text,
      location: {
        lng: feature.center[0],
        lat: feature.center[1]
      },
      context: feature.place_name
    }));
  } catch (error) {
    console.error('Error getting location suggestions:', error);
    return [];
  }
};

export const reverseGeocode = async (location: MapLocation): Promise<string> => {
  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${location.lng},${
        location.lat
      }.json?access_token=${mapboxgl.accessToken}&country=US`
    );

    if (!response.ok) {
      throw new Error('Reverse geocoding failed');
    }

    const data = await response.json();
    if (!data.features || data.features.length === 0) {
      throw new Error('No results found');
    }

    return data.features[0].place_name;
  } catch (error) {
    console.error('Error reverse geocoding:', error);
    throw error;
  }
};

export const calculateDistance = (point1: MapLocation, point2: MapLocation): number => {
  const R = 3959; // Earth's radius in miles
  const lat1 = toRad(point1.lat);
  const lat2 = toRad(point2.lat);
  const dLat = toRad(point2.lat - point1.lat);
  const dLon = toRad(point2.lng - point1.lng);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return Math.round(distance * 10) / 10; // Round to 1 decimal place
};

const toRad = (degrees: number): number => {
  return (degrees * Math.PI) / 180;
};

export const getBoundingBox = (
  center: MapLocation,
  radiusMiles: number
): [number, number, number, number] => {
  const R = 3959; // Earth's radius in miles
  const lat = toRad(center.lat);
  const lon = toRad(center.lng);
  const distance = radiusMiles / R;

  const minLat = Math.asin(
    Math.sin(lat) * Math.cos(distance) +
      Math.cos(lat) * Math.sin(distance) * -1
  );
  const maxLat = Math.asin(
    Math.sin(lat) * Math.cos(distance) + Math.cos(lat) * Math.sin(distance)
  );

  const deltaLon = Math.asin(Math.sin(distance) / Math.cos(lat));
  const minLon = lon - deltaLon;
  const maxLon = lon + deltaLon;

  return [
    (minLon * 180) / Math.PI,
    (minLat * 180) / Math.PI,
    (maxLon * 180) / Math.PI,
    (maxLat * 180) / Math.PI
  ];
};

export const initializeMap = (
  container: string | HTMLElement,
  options: Partial<mapboxgl.MapboxOptions> = {}
): mapboxgl.Map => {
  return new mapboxgl.Map({
    container,
    style: 'mapbox://styles/mapbox/streets-v11',
    center: [-96, 37.8], // Center of US
    zoom: 3,
    ...options
  });
};

export const createMarker = (options: Partial<mapboxgl.MarkerOptions> = {}): mapboxgl.Marker => {
  return new mapboxgl.Marker(options);
};

export const createPopup = (options: Partial<mapboxgl.PopupOptions> = {}): mapboxgl.Popup => {
  return new mapboxgl.Popup(options);
};
