import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Alert
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { getYardSaleListings, ListingsQueryParams } from '../services/firebase';
import { UserSale, ParkingType } from '../types/user';
import YardSaleList from '../components/map/YardSaleList';
import MapFilters from '../components/map/MapFilters';
import { DocumentSnapshot } from 'firebase/firestore';

const SearchResultsPage = () => {
  const location = useLocation();
  const [sales, setSales] = useState<UserSale[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [lastDoc, setLastDoc] = useState<DocumentSnapshot | null>(null);
  const [hasMore, setHasMore] = useState(true);

  // Parse search params
  const searchParams = new URLSearchParams(location.search);
  const initialFilters = {
    location: searchParams.get('location') || '',
    categories: searchParams.get('categories')?.split(',').filter(Boolean) || [],
    parkingType: (searchParams.get('parking') as ParkingType) || 'none',
    startDate: searchParams.get('startDate') || undefined,
    endDate: searchParams.get('endDate') || undefined,
    distance: searchParams.get('distance') ? parseInt(searchParams.get('distance')!) : 25,
    coordinates: searchParams.get('lat') && searchParams.get('lng') ? {
      lat: parseFloat(searchParams.get('lat')!),
      lng: parseFloat(searchParams.get('lng')!)
    } : undefined
  };

  const [filters, setFilters] = useState(initialFilters);

  const fetchListings = async (isInitial = false) => {
    try {
      setLoading(true);
      setError(null);

      const queryParams: ListingsQueryParams = {
        ...filters,
        limit: 10
      };

      if (!isInitial && lastDoc) {
        // Add pagination logic here when implementing with Firebase
      }

      const response = await getYardSaleListings(queryParams);
      
      setSales(prev => isInitial ? response.listings : [...prev, ...response.listings]);
      setLastDoc(response.lastDoc || null);
      setHasMore(response.listings.length === 10);
    } catch (err) {
      setError('Failed to load yard sales. Please try again.');
      console.error('Error fetching listings:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchListings(true);
  }, [filters]);

  const handleFilterChange = (newFilters: Partial<typeof filters>) => {
    setFilters(prev => ({
      ...prev,
      ...newFilters
    }));
  };

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      fetchListings(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, position: 'sticky', top: 24 }}>
            <MapFilters
              filters={filters}
              onChange={handleFilterChange}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={9}>
          {error ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          ) : (
            <>
              <Typography variant="h5" gutterBottom>
                {filters.location ? (
                  `Yard Sales near ${filters.location}`
                ) : (
                  'All Yard Sales'
                )}
              </Typography>

              {loading && sales.length === 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                  <CircularProgress />
                </Box>
              ) : sales.length === 0 ? (
                <Alert severity="info" sx={{ mb: 2 }}>
                  No yard sales found in this area. Try expanding your search radius or adjusting your filters.
                </Alert>
              ) : (
                <YardSaleList
                  sales={sales}
                  onLoadMore={handleLoadMore}
                  loading={loading}
                  hasMore={hasMore}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SearchResultsPage;
