import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Avatar,
  Stack,
  Chip,
  Grid,
  Rating,
  Tabs,
  Tab,
  Card,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
  Alert
} from '@mui/material';
import { UserProfile, UserSale } from '../../types/user';
import { auth, getUserYardSales } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface PrivateProfileProps {
  profile: UserProfile;
}

const PrivateProfile: React.FC<PrivateProfileProps> = ({ profile }) => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [sales, setSales] = useState<UserSale[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSales = async () => {
      if (!user) return;
      
      try {
        setLoading(true);
        setError('');
        const userSales = await getUserYardSales(user.uid);
        setSales(userSales);
      } catch (err) {
        console.error('Error fetching sales:', err);
        setError('Failed to load your sales');
      } finally {
        setLoading(false);
      }
    };

    fetchSales();
  }, [user]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const renderSalesList = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      );
    }

    if (sales.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography color="text.secondary" gutterBottom>
            You haven't created any sales yet
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate('/create-sale')}
            sx={{ mt: 2 }}
          >
            Create Your First Sale
          </Button>
        </Box>
      );
    }

    return (
      <Grid container spacing={3}>
        {sales.map((sale) => (
          <Grid item xs={12} sm={6} key={sale.id}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {sale.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {sale.date} • {sale.time.start} - {sale.time.end}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {sale.location}
                </Typography>
                <Box sx={{ mt: 1 }}>
                  {sale.categories.map((category) => (
                    <Chip
                      key={category}
                      label={category}
                      size="small"
                      sx={{ mr: 0.5, mb: 0.5 }}
                    />
                  ))}
                </Box>
              </CardContent>
              <CardActions>
                <Button 
                  size="small" 
                  onClick={() => navigate(`/sale/${sale.id}`)}
                >
                  View Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Stack spacing={3}>
        {/* Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Avatar
            src={profile.avatar}
            sx={{ width: 80, height: 80 }}
          >
            {profile.firstName[0]}{profile.lastName[0]}
          </Avatar>
          <Box>
            <Typography variant="h5">
              {profile.firstName} {profile.lastName}
              {profile.isVerified && (
                <Chip
                  label="Verified"
                  color="primary"
                  size="small"
                  sx={{ ml: 1 }}
                />
              )}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Member since {new Date(profile.joinDate).toLocaleDateString()}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {profile.location}
            </Typography>
          </Box>
        </Box>

        {/* Stats */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="text.secondary">
                Rating
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Rating value={profile.stats.averageRating} readOnly precision={0.5} />
                <Typography>
                  ({profile.stats.reviewCount} reviews)
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="text.secondary">
                Sales
              </Typography>
              <Typography>
                {profile.stats.completedSales} completed • {profile.stats.activeSales} active
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        {/* Tabs */}
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Sales" />
              <Tab label="Settings" />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            {renderSalesList()}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {/* Settings content */}
            <Typography>Profile settings will appear here</Typography>
          </TabPanel>
        </Box>
      </Stack>
    </Paper>
  );
};

export default PrivateProfile;
