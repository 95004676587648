// Category colors for consistent styling across the app
export const categoryColors: { [key: string]: string } = {
  'Antiques & Collectibles': '#845ef7',
  'Art & Craft Supplies': '#ffd43b',
  'Automotive': '#495057',
  'Baby & Kids': '#94d82d',
  'Books': '#20c997',
  'Clothing': '#ff6b6b',
  'DIY & Home Improvement': '#e67700',
  'Electronics': '#339af0',
  'Furniture': '#ff6b6b',
  'Gardening': '#37b24d',
  'Health & Beauty': '#f06595',
  'Home Decor': '#ff922b',
  'Jewelry & Accessories': '#cc5de8',
  'Kitchen & Dining': '#fd7e14',
  'Music & Instruments': '#5c7cfa',
  'Office Supplies': '#868e96',
  'Other': '#868e96',
  'Pet Supplies': '#74c0fc',
  'Seasonal & Holiday Items': '#fab005',
  'Sports & Outdoor Equipment': '#1098ad',
  'Tools': '#e67700',
  'Toys': '#ffd43b',
  'Video Games': '#5c7cfa'
};

// Available categories for yard sales
export const CATEGORIES = [
  'Antiques & Collectibles',
  'Art & Craft Supplies',
  'Automotive',
  'Baby & Kids',
  'Books',
  'Clothing',
  'DIY & Home Improvement',
  'Electronics',
  'Furniture',
  'Gardening',
  'Health & Beauty',
  'Home Decor',
  'Jewelry & Accessories',
  'Kitchen & Dining',
  'Music & Instruments',
  'Office Supplies',
  'Other',
  'Pet Supplies',
  'Seasonal & Holiday Items',
  'Sports & Outdoor Equipment',
  'Tools',
  'Toys',
  'Video Games'
];

// Item conditions
export const ITEM_CONDITIONS = [
  'New',
  'Like New',
  'Excellent',
  'Good',
  'Fair',
  'As Is'
];

// Available search radius options (in miles)
export const DISTANCES = [5, 10, 25, 50];

// Default search radius
export const DEFAULT_SEARCH_RADIUS = 25;

// Maximum number of items to load per page
export const PAGE_SIZE = 10;

// Default map center (Boise, ID)
export const DEFAULT_MAP_CENTER = {
  lat: 43.6150,
  lng: -116.2023
};
