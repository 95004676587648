import React from 'react';
import {
  Box,
  Typography,
  Stack,
  Avatar,
  Chip
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import ReviewStars from './ReviewStars';

interface SellerProfileProps {
  seller: {
    id: string;
    name: string;
    isVerified: boolean;
  };
  rating: number;
  reviewCount: number;
}

const SellerProfile: React.FC<SellerProfileProps> = ({
  seller,
  rating,
  reviewCount
}) => {
  return (
    <Stack spacing={2}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Avatar
          sx={{
            width: 64,
            height: 64,
            bgcolor: 'primary.main'
          }}
        >
          {seller.name[0]}
        </Avatar>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h6">
              {seller.name}
            </Typography>
            {seller.isVerified && (
              <Chip
                icon={<VerifiedIcon />}
                label="Verified Seller"
                size="small"
                color="primary"
              />
            )}
          </Box>
          <Box sx={{ mt: 0.5 }}>
            <ReviewStars 
              value={rating}
              showCount
              count={reviewCount}
              size="small"
            />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default SellerProfile;
