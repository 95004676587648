import React, { useState } from 'react';
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Chip,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Divider,
  Alert,
  TextField
} from '@mui/material';
import { ParkingType } from '../../types/user';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import NearMeIcon from '@mui/icons-material/NearMe';
import HomeIcon from '@mui/icons-material/Home';
import BlockIcon from '@mui/icons-material/Block';
import { CATEGORIES } from '../../config/constants';
import { addDays, isBefore, startOfToday } from 'date-fns';
import DateRangePresets from '../DateRangePresets';

export interface MapFiltersProps {
  filters: {
    searchQuery?: string;  // Make searchQuery optional
    categories: string[];
    parkingType: ParkingType;
    startDate?: string;
    endDate?: string;
  };
  onChange: (newFilters: Partial<MapFiltersProps['filters']>) => void;
}

const MapFilters: React.FC<MapFiltersProps> = ({ filters, onChange }) => {
  const [dateError, setDateError] = useState<string | null>(null);

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateError(null);
    const startDate = event.target.value;
    const endDate = filters.endDate;

    if (startDate) {
      // Don't allow dates before today
      if (isBefore(new Date(startDate), startOfToday())) {
        setDateError("Start date cannot be in the past");
        return;
      }
      // Don't allow dates more than 90 days in the future
      const maxDate = addDays(new Date(), 90);
      if (isBefore(maxDate, new Date(startDate))) {
        setDateError("Start date cannot be more than 90 days in the future");
        return;
      }
      // If end date exists, validate range
      if (endDate && isBefore(new Date(endDate), new Date(startDate))) {
        setDateError("Start date must be before end date");
        return;
      }
    }

    onChange({ startDate });
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateError(null);
    const endDate = event.target.value;
    const startDate = filters.startDate;

    if (endDate) {
      // Don't allow dates more than 90 days in the future
      const maxDate = addDays(new Date(), 90);
      if (isBefore(maxDate, new Date(endDate))) {
        setDateError("End date cannot be more than 90 days in the future");
        return;
      }
      // If start date exists, validate range
      if (startDate && isBefore(new Date(endDate), new Date(startDate))) {
        setDateError("End date must be after start date");
        return;
      }
    }

    onChange({ endDate });
  };

  const handleCategoryToggle = (category: string) => {
    const newCategories = filters.categories.includes(category)
      ? filters.categories.filter(c => c !== category)
      : [...filters.categories, category];
    
    onChange({ categories: newCategories });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Filters
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle2" gutterBottom>
          Date Range
        </Typography>
        <Stack spacing={2}>
          <DateRangePresets 
            onSelect={(start, end) => {
              onChange({
                startDate: start.toISOString().split('T')[0],
                endDate: end.toISOString().split('T')[0]
              });
            }}
          />
          <Divider />
          <Stack spacing={2}>
            <TextField
              label="Start Date"
              type="date"
              value={filters.startDate || ''}
              onChange={handleStartDateChange}
              error={!!dateError}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: startOfToday().toISOString().split('T')[0],
                max: addDays(new Date(), 90).toISOString().split('T')[0]
              }}
              fullWidth
              size="small"
            />
            <TextField
              label="End Date"
              type="date"
              value={filters.endDate || ''}
              onChange={handleEndDateChange}
              error={!!dateError}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: filters.startDate || startOfToday().toISOString().split('T')[0],
                max: addDays(new Date(), 90).toISOString().split('T')[0]
              }}
              fullWidth
              size="small"
            />
          </Stack>
          {dateError && (
            <Alert severity="error" sx={{ mt: 1 }}>
              {dateError}
            </Alert>
          )}
        </Stack>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle2" gutterBottom>
          Categories
        </Typography>
        <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
          {CATEGORIES.map(category => (
            <Chip
              key={category}
              label={category}
              onClick={() => handleCategoryToggle(category)}
              color={filters.categories.includes(category) ? 'primary' : 'default'}
              sx={{ mb: 1 }}
            />
          ))}
        </Stack>
      </Box>

      <FormControl component="fieldset" sx={{ mb: 3 }}>
        <FormLabel component="legend">Parking Options</FormLabel>
        <RadioGroup
          value={filters.parkingType}
          onChange={(e) => onChange({ parkingType: e.target.value as ParkingType })}
        >
          <FormControlLabel
            value="street"
            control={<Radio />}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <DirectionsCarIcon color="info" />
                <span>Street Parking</span>
              </Box>
            }
          />
          <FormControlLabel
            value="nearby"
            control={<Radio />}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <NearMeIcon color="info" />
                <span>Nearby Parking</span>
              </Box>
            }
          />
          <FormControlLabel
            value="driveway"
            control={<Radio />}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <HomeIcon color="success" />
                <span>Driveway Parking</span>
              </Box>
            }
          />
          <FormControlLabel
            value="none"
            control={<Radio />}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <BlockIcon color="disabled" />
                <span>Any Parking</span>
              </Box>
            }
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default MapFilters;
