import React from 'react';
import {
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Stack,
  IconButton,
  Paper,
  MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReservableItem } from '../../types/reservation';
import { CATEGORIES, ITEM_CONDITIONS } from '../../config/constants';

interface ReservableItemFormProps {
  items: ReservableItem[];
  onAddItem: (item: ReservableItem) => void;
  onRemoveItem: (itemId: string) => void;
  onUpdateItem: (itemId: string, updates: Partial<ReservableItem>) => void;
}

const ReservableItemForm: React.FC<ReservableItemFormProps> = ({
  items,
  onAddItem,
  onRemoveItem,
  onUpdateItem
}) => {
  const handleAddItem = () => {
    const newItem: ReservableItem = {
      id: `item-${Date.now()}`,
      name: '',
      price: 0,
      isReservable: true
    };
    onAddItem(newItem);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Reservable Items
      </Typography>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        Add items that buyers can reserve before the sale
      </Typography>

      <Stack spacing={2} mt={2}>
        {items.map((item) => (
          <Paper key={item.id} sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <TextField
                  label="Item Name"
                  value={item.name}
                  onChange={(e) => onUpdateItem(item.id, { name: e.target.value })}
                  fullWidth
                  sx={{ mr: 2 }}
                />
                <IconButton
                  onClick={() => onRemoveItem(item.id)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>

              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  label="Price"
                  type="number"
                  value={item.price}
                  onChange={(e) => onUpdateItem(item.id, { price: Number(e.target.value) })}
                  InputProps={{
                    startAdornment: <Typography>$</Typography>
                  }}
                  sx={{ width: 150 }}
                />
                <TextField
                  select
                  label="Condition"
                  value={item.condition || ''}
                  onChange={(e) => onUpdateItem(item.id, { condition: e.target.value })}
                  sx={{ width: 150 }}
                >
                  {ITEM_CONDITIONS.map((condition) => (
                    <MenuItem key={condition} value={condition}>
                      {condition}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  label="Category"
                  value={item.category || ''}
                  onChange={(e) => onUpdateItem(item.id, { category: e.target.value })}
                  sx={{ width: 150 }}
                >
                  {CATEGORIES.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <TextField
                label="Description"
                value={item.description || ''}
                onChange={(e) => onUpdateItem(item.id, { description: e.target.value })}
                multiline
                rows={2}
                fullWidth
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.isReservable}
                    onChange={(e) => onUpdateItem(item.id, { isReservable: e.target.checked })}
                  />
                }
                label="Allow Reservations"
              />
            </Stack>
          </Paper>
        ))}

        <Button
          variant="outlined"
          onClick={handleAddItem}
          fullWidth
        >
          Add Item
        </Button>
      </Stack>
    </Box>
  );
};

export default ReservableItemForm;
