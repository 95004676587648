import React from 'react';
import { Box, Rating, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

interface ReviewStarsProps {
  value: number;
  readOnly?: boolean;
  showCount?: boolean;
  count?: number;
  size?: 'small' | 'medium' | 'large';
  onChange?: (value: number | null) => void;
}

const ReviewStars: React.FC<ReviewStarsProps> = ({
  value,
  readOnly = true,
  showCount = false,
  count = 0,
  size = 'medium',
  onChange
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Rating
        value={value}
        readOnly={readOnly}
        onChange={(_, newValue) => onChange?.(newValue)}
        precision={0.5}
        size={size}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize={size} />}
      />
      {showCount && (
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ ml: 1 }}
        >
          ({count})
        </Typography>
      )}
    </Box>
  );
};

export default ReviewStars;
