import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Avatar,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { auth, logOut } from '../../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const Navbar = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMenuAnchor, setMobileMenuAnchor] = React.useState<null | HTMLElement>(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMobileMenuAnchor(null);
  };

  const handleSignOut = async () => {
    try {
      await logOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
    handleMenuClose();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { navigate('/profile'); handleMenuClose(); }}>
        Profile
      </MenuItem>
      <MenuItem onClick={() => { navigate('/profile'); handleMenuClose(); }}>
        My Sales
      </MenuItem>
      <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMenuAnchor}
      open={Boolean(mobileMenuAnchor)}
      onClose={handleMenuClose}
    >
      {user ? (
        <>
          <MenuItem onClick={() => { navigate('/create-sale'); handleMenuClose(); }}>
            Create Sale
          </MenuItem>
          <MenuItem onClick={() => { navigate('/profile'); handleMenuClose(); }}>
            Profile
          </MenuItem>
          <MenuItem onClick={() => { navigate('/profile'); handleMenuClose(); }}>
            My Sales
          </MenuItem>
          <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
        </>
      ) : (
        <>
          <MenuItem onClick={() => { navigate('/signin'); handleMenuClose(); }}>
            Sign In
          </MenuItem>
          <MenuItem onClick={() => { navigate('/subscription'); handleMenuClose(); }}>
            Sign Up
          </MenuItem>
        </>
      )}
    </Menu>
  );

  return (
    <AppBar 
      position="sticky" 
      sx={{ 
        backgroundColor: 'white',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Toolbar>
        {isMobile && (
          <IconButton
            edge="start"
            onClick={handleMobileMenuOpen}
            sx={{ color: 'primary.main' }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <Typography
          variant="h5"
          component={RouterLink}
          to="/"
          sx={{
            textDecoration: 'none',
            color: 'primary.main',
            flexGrow: 1,
            fontSize: '1.5rem',
            fontWeight: 500
          }}
        >
          YardFinder
        </Typography>

        {!isMobile && (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {user && (
              <Button 
                sx={{ color: 'primary.main' }} 
                component={RouterLink} 
                to="/create-sale"
              >
                Create Sale
              </Button>
            )}
          </Box>
        )}

        {user ? (
          <IconButton
            onClick={handleProfileMenuOpen}
            sx={{ padding: 0.5 }}
          >
            <Avatar
              alt={user.displayName || undefined}
              src={user.photoURL || undefined}
              sx={{ width: 32, height: 32 }}
            />
          </IconButton>
        ) : (
          !isMobile && (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="outlined"
                component={RouterLink}
                to="/signin"
                sx={{
                  color: 'primary.main',
                  borderColor: 'primary.main',
                  '&:hover': {
                    borderColor: 'primary.dark',
                    backgroundColor: 'rgba(25, 118, 210, 0.04)'
                  }
                }}
              >
                Sign In
              </Button>
              <Button
                variant="contained"
                component={RouterLink}
                to="/subscription"
                sx={{
                  color: 'white',
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.dark'
                  }
                }}
              >
                Sign Up
              </Button>
            </Box>
          )
        )}
      </Toolbar>
      {renderMenu}
      {renderMobileMenu}
    </AppBar>
  );
};

export default Navbar;
