import React, { useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { SUBSCRIPTION_PLANS, ADD_ONS, PricingOption } from '../types/subscription';

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const [billingInterval, setBillingInterval] = useState<'monthly' | 'yearly'>('monthly');

  const handleIntervalChange = (
    event: React.MouseEvent<HTMLElement>,
    newInterval: 'monthly' | 'yearly' | null
  ) => {
    if (newInterval !== null) {
      setBillingInterval(newInterval);
    }
  };

  const formatPrice = (pricing: PricingOption[]) => {
    const currentPricing = pricing.find(p => p.interval === billingInterval);
    if (!currentPricing) return '$0';
    return `$${currentPricing.price.toFixed(2)}`;
  };

  const getButtonText = (tier: string) => {
    switch (tier) {
      case 'free':
        return 'Get Started';
      case 'premium':
        return 'Go Premium';
      case 'preferred':
        return 'Go Preferred';
      default:
        return 'Select Plan';
    }
  };

  const handlePlanSelection = (tier: string) => {
    // Pass the selected tier and billing interval to the signup page
    navigate('/signup', {
      state: {
        tier,
        billingInterval,
        price: SUBSCRIPTION_PLANS.find(plan => plan.tier === tier)?.pricing.find(p => p.interval === billingInterval)?.price
      }
    });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Choose Your Plan
      </Typography>
      <Typography variant="subtitle1" align="center" color="text.secondary" sx={{ mb: 6 }}>
        Select the perfect plan for your yard sale needs
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 6 }}>
        <ToggleButtonGroup
          value={billingInterval}
          exclusive
          onChange={handleIntervalChange}
          aria-label="billing interval"
          sx={{ backgroundColor: 'background.paper' }}
        >
          <ToggleButton value="monthly">
            Monthly Billing
          </ToggleButton>
          <ToggleButton value="yearly">
            Yearly Billing
            <Chip
              label="Save up to 33%"
              color="primary"
              size="small"
              sx={{ ml: 1 }}
            />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Grid container spacing={4}>
        {SUBSCRIPTION_PLANS.map((plan) => (
          <Grid item xs={12} md={4} key={plan.tier}>
            <Paper 
              elevation={plan.recommended ? 8 : 1}
              sx={{ 
                p: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                border: plan.recommended ? 2 : 0,
                borderColor: 'primary.main'
              }}
            >
              {plan.recommended && (
                <Chip
                  label="Recommended"
                  color="primary"
                  sx={{
                    position: 'absolute',
                    top: -12,
                    right: 24,
                  }}
                />
              )}

              <Typography variant="h5" component="h2" gutterBottom>
                {plan.name}
              </Typography>
              
              <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 3 }}>
                {plan.description}
              </Typography>

              <Typography variant="h4" component="div" sx={{ mb: 2 }}>
                {formatPrice(plan.pricing)}
                <Typography variant="subtitle1" component="span" color="text.secondary">
                  /{billingInterval === 'monthly' ? 'mo' : 'yr'}
                </Typography>
              </Typography>

              {plan.pricing.find(p => p.interval === billingInterval)?.savings && (
                <Typography variant="subtitle2" color="success.main" sx={{ mb: 2 }}>
                  Save {plan.pricing.find(p => p.interval === billingInterval)?.savings}%
                </Typography>
              )}

              <Button
                variant={plan.recommended ? "contained" : "outlined"}
                fullWidth
                size="large"
                sx={{ mb: 4 }}
                onClick={() => handlePlanSelection(plan.tier)}
              >
                {getButtonText(plan.tier)}
              </Button>

              <Divider sx={{ my: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Features
                </Typography>
              </Divider>

              <List sx={{ flexGrow: 1 }}>
                {plan.features.map((feature) => (
                  <ListItem key={feature.name} sx={{ py: 1 }}>
                    <ListItemIcon>
                      {feature.included ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <CancelIcon color="disabled" />
                      )}
                    </ListItemIcon>
                    <ListItemText 
                      primary={feature.name}
                      secondary={feature.details}
                      primaryTypographyProps={{
                        variant: 'body2',
                        color: feature.included ? 'text.primary' : 'text.disabled'
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 8 }}>
        <Typography variant="h5" gutterBottom align="center">
          Add-On Features
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" sx={{ mb: 4 }}>
          Enhance your experience with these additional features
        </Typography>

        <Grid container spacing={3}>
          {ADD_ONS.map((addon) => (
            <Grid item xs={12} sm={6} md={3} key={addon.id}>
              <Paper sx={{ p: 3, height: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  {addon.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  {addon.description}
                </Typography>
                <Typography variant="h6" color="primary">
                  ${addon.price.toFixed(2)}
                </Typography>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Add to Plan
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default SubscriptionPage;
