import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  CircularProgress,
  Typography,
  Alert,
  Tooltip
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import { IMAGE_UPLOAD_CONFIG } from '../services/storage';

const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

interface ImageUploadProps {
  images: string[];
  onImagesChange: (images: string[], files?: FileList) => void;
  maxImages?: number;
  loading?: boolean;
  error?: string;
}

interface ValidationResult {
  valid: boolean;
  error: string | null;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  images,
  onImagesChange,
  maxImages = IMAGE_UPLOAD_CONFIG.maxFiles,
  loading = false,
  error
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState(false);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    // Clean up preview URLs when component unmounts
    return () => {
      previewUrls.forEach(url => {
        if (url.startsWith('blob:')) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, []);

  const validateFiles = (files: File[]): ValidationResult => {
    for (const file of files) {
      // Check file type
      if (!IMAGE_UPLOAD_CONFIG.allowedTypes.includes(file.type)) {
        return {
          valid: false,
          error: `Invalid file type: ${file.name}. Only JPG, PNG, and WebP images are allowed.`
        };
      }

      // Check file size
      if (file.size > IMAGE_UPLOAD_CONFIG.maxFileSize) {
        return {
          valid: false,
          error: `File too large: ${file.name} (${formatFileSize(file.size)}). Maximum size is ${formatFileSize(IMAGE_UPLOAD_CONFIG.maxFileSize)}.`
        };
      }
    }

    return { valid: true, error: null };
  };

  const handleFiles = (fileList: FileList) => {
    setValidationError(null);
    const remainingSlots = maxImages - images.length;
    
    if (remainingSlots <= 0) {
      setValidationError(`Maximum ${maxImages} images allowed.`);
      return;
    }

    const files = Array.from(fileList).slice(0, remainingSlots);
    const validation = validateFiles(files);

    if (!validation.valid && validation.error) {
      setValidationError(validation.error);
      return;
    }

    // Create preview URLs for the new files
    const newPreviewUrls = files.map(file => URL.createObjectURL(file));
    
    // Update preview URLs
    setPreviewUrls(prev => [...prev, ...newPreviewUrls]);
    
    // Pass both preview URLs and files to parent
    onImagesChange([...images, ...newPreviewUrls], fileList);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFiles(e.dataTransfer.files);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFiles(e.target.files);
    }
  };

  const handleRemoveImage = (index: number) => {
    // Clean up preview URL if it's a blob URL
    if (previewUrls[index]?.startsWith('blob:')) {
      URL.revokeObjectURL(previewUrls[index]);
    }

    // Remove from preview URLs
    const newPreviewUrls = [...previewUrls];
    newPreviewUrls.splice(index, 1);
    setPreviewUrls(newPreviewUrls);

    // Remove from images array
    const newImages = [...images];
    newImages.splice(index, 1);
    onImagesChange(newImages);
  };

  return (
    <Box>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept={IMAGE_UPLOAD_CONFIG.allowedTypes.join(',')}
        multiple
        style={{ display: 'none' }}
      />

      <Tooltip title={`Maximum file size: ${formatFileSize(IMAGE_UPLOAD_CONFIG.maxFileSize)}`}>
        <Box
          sx={{
            border: '2px dashed',
            borderColor: dragActive ? 'primary.main' : 'grey.300',
            borderRadius: 1,
            p: 3,
            textAlign: 'center',
            backgroundColor: dragActive ? 'action.hover' : 'background.paper',
            cursor: 'pointer',
            transition: 'all 0.2s ease-in-out'
          }}
          onClick={handleClick}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <AddPhotoAlternateIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 1 }} />
          <Typography variant="body1" color="text.secondary" gutterBottom>
            Drag and drop images here or click to select
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {images.length} / {maxImages} images uploaded
          </Typography>
          <Typography variant="caption" color="text.secondary" display="block">
            Accepted formats: JPG, PNG, WebP
          </Typography>
        </Box>
      </Tooltip>

      {(error || validationError) && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error || validationError}
        </Alert>
      )}

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {images.length > 0 && (
        <ImageList sx={{ mt: 2 }} cols={3} rowHeight={164}>
          {images.map((image, index) => (
            <ImageListItem key={index} sx={{ position: 'relative' }}>
              <img
                src={previewUrls[index] || image}
                alt={`Upload ${index + 1}`}
                loading="lazy"
                style={{ height: '100%', objectFit: 'cover' }}
              />
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.7)'
                  }
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveImage(index);
                }}
              >
                <DeleteIcon sx={{ color: 'white' }} />
              </IconButton>
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </Box>
  );
};

export default ImageUpload;
