import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
  Box,
  Button,
  Chip,
  Stack,
  CircularProgress
} from '@mui/material';
import { UserSale, ParkingType } from '../../types/user';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import NearMeIcon from '@mui/icons-material/NearMe';
import HomeIcon from '@mui/icons-material/Home';

interface YardSaleListProps {
  sales: UserSale[];
  onLoadMore?: () => void;
  loading?: boolean;
  hasMore?: boolean;
  selectedSaleId?: string;
  onSaleSelect?: (sale: UserSale) => void;
}

const getParkingIcon = (parkingType: ParkingType) => {
  switch (parkingType) {
    case 'street':
      return <DirectionsCarIcon color="info" fontSize="small" />;
    case 'nearby':
      return <NearMeIcon color="info" fontSize="small" />;
    case 'driveway':
      return <HomeIcon color="success" fontSize="small" />;
    default:
      return <LocalParkingIcon color="disabled" fontSize="small" />;
  }
};

const getParkingText = (parkingType: ParkingType) => {
  switch (parkingType) {
    case 'street':
      return 'Street Parking Available';
    case 'nearby':
      return 'Nearby Parking Available';
    case 'driveway':
      return 'Driveway Parking Available';
    default:
      return 'No Parking Information';
  }
};

const getParkingColor = (parkingType: ParkingType) => {
  switch (parkingType) {
    case 'street':
    case 'nearby':
      return 'info.main';
    case 'driveway':
      return 'success.main';
    default:
      return 'text.secondary';
  }
};

const YardSaleList: React.FC<YardSaleListProps> = ({
  sales,
  onLoadMore,
  loading = false,
  hasMore = false,
  selectedSaleId,
  onSaleSelect
}) => {
  if (sales.length === 0 && !loading) {
    return (
      <Paper sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="text.secondary">
          No yard sales found in this area
        </Typography>
      </Paper>
    );
  }

  return (
    <Stack spacing={2}>
      <List disablePadding>
        {sales.map((sale) => (
          <Paper
            key={sale.id}
            sx={{
              mb: 2,
              cursor: onSaleSelect ? 'pointer' : 'default',
              border: selectedSaleId === sale.id ? 2 : 0,
              borderColor: 'primary.main'
            }}
            onClick={() => onSaleSelect?.(sale)}
          >
            <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <Box sx={{ width: '100%', mb: 1 }}>
                <Typography variant="h6" gutterBottom>
                  {sale.title}
                  {sale.featured && (
                    <Chip
                      label="Featured"
                      color="primary"
                      size="small"
                      sx={{ ml: 1 }}
                    />
                  )}
                </Typography>
              </Box>

              <Stack spacing={1} sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <LocationOnIcon color="action" fontSize="small" />
                  <Typography variant="body2">
                    {sale.location}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CalendarTodayIcon color="action" fontSize="small" />
                  <Typography variant="body2">
                    {new Date(sale.date).toLocaleDateString()} • {sale.time.start} - {sale.time.end}
                  </Typography>
                </Box>

                {sale.parkingType !== 'none' && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {getParkingIcon(sale.parkingType)}
                    <Typography variant="body2" color={getParkingColor(sale.parkingType)}>
                      {getParkingText(sale.parkingType)}
                    </Typography>
                  </Box>
                )}

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                  {sale.categories.map((category) => (
                    <Chip
                      key={category}
                      label={category}
                      size="small"
                      variant="outlined"
                    />
                  ))}
                </Box>
              </Stack>
            </ListItem>
          </Paper>
        ))}
      </List>

      {onLoadMore && (hasMore || loading) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            onClick={onLoadMore}
            disabled={loading}
            variant="outlined"
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? 'Loading...' : 'Load More'}
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default YardSaleList;
