import React, { useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  Paper,
  Chip,
  Stack,
  IconButton,
  Popover,
  Slider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Divider,
  Alert
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TuneIcon from '@mui/icons-material/Tune';
import MapIcon from '@mui/icons-material/Map';
import { DISTANCES, CATEGORIES, DEFAULT_SEARCH_RADIUS } from '../config/constants';
import { addDays, isBefore, startOfToday } from 'date-fns';
import DateRangePresets from './DateRangePresets';
import LocationField from './LocationField';
import { Coordinates } from '../types/user';

const SearchBar = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [coordinates, setCoordinates] = useState<Coordinates>();
  const [distance, setDistance] = useState(DEFAULT_SEARCH_RADIUS);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [dateError, setDateError] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleLocationChange = (value: string, coords?: Coordinates) => {
    setSearchQuery(value);
    setCoordinates(coords);
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateError(null);
    const newStartDate = event.target.value;

    if (newStartDate) {
      // Don't allow dates before today
      if (isBefore(new Date(newStartDate), startOfToday())) {
        setDateError("Start date cannot be in the past");
        return;
      }
      // Don't allow dates more than 90 days in the future
      const maxDate = addDays(new Date(), 90);
      if (isBefore(maxDate, new Date(newStartDate))) {
        setDateError("Start date cannot be more than 90 days in the future");
        return;
      }
      // If end date exists, validate range
      if (endDate && isBefore(new Date(endDate), new Date(newStartDate))) {
        setDateError("Start date must be before end date");
        return;
      }
    }

    setStartDate(newStartDate);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateError(null);
    const newEndDate = event.target.value;

    if (newEndDate) {
      // Don't allow dates more than 90 days in the future
      const maxDate = addDays(new Date(), 90);
      if (isBefore(maxDate, new Date(newEndDate))) {
        setDateError("End date cannot be more than 90 days in the future");
        return;
      }
      // If start date exists, validate range
      if (startDate && isBefore(new Date(newEndDate), new Date(startDate))) {
        setDateError("End date must be after start date");
        return;
      }
    }

    setEndDate(newEndDate);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Check if we have both a search query and coordinates (meaning a suggestion was selected)
    if (searchQuery.trim() && coordinates) {
      const params = new URLSearchParams();
      params.append('location', searchQuery);
      
      //coordinates
      params.append('lat', coordinates.lat.toString());
      params.append('lng', coordinates.lng.toString());
      
      //categories
      if (selectedCategories.length > 0) {
        params.append('categories', selectedCategories.join(','));
      }
      
      //Dates
      if (startDate) {
        params.append('startDate', startDate);
      }
      
      if (endDate) {
        params.append('endDate', endDate);
      }
      
      navigate(`/map?${params.toString()}`);
    } else if (searchQuery.trim() && !coordinates) {
      // If there's a search query but no coordinates, it means user didn't select from suggestions
      // Error Message
      alert('Please Select a Location from the Dropdown Suggestions.');
    }
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleDistanceChange = (event: Event, newValue: number | number[]) => {
    setDistance(newValue as number);
  };

  const handleCategoryToggle = (category: string) => {
    const currentIndex = selectedCategories.indexOf(category);
    const newCategories = [...selectedCategories];

    if (currentIndex === -1) {
      newCategories.push(category);
    } else {
      newCategories.splice(currentIndex, 1);
    }

    setSelectedCategories(newCategories);
  };

  const handleResetFilters = () => {
    setDistance(DEFAULT_SEARCH_RADIUS);
    setSelectedCategories([]);
    setStartDate('');
    setEndDate('');
    setDateError(null);
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const distanceMarks = DISTANCES.map(value => ({
    value,
    label: `${value}mi`
  }));

  const open = Boolean(anchorEl);

  return (
    <Box 
      sx={{ 
        bgcolor: 'primary.main',
        pb: 4,
        width: '100vw',
        position: 'relative',
        marginLeft: '-50vw',
        left: '50%',
        right: '50%',
        marginRight: '-50vw'
      }}
    >
      <Box sx={{ maxWidth: '1200px', mx: 'auto', px: { xs: 2, sm: 3 } }}>
        <Typography
          variant="h4"
          align="center"
          sx={{ color: 'white', mb: 3, fontWeight: 500, pt: 4 }}
        >
          Find Yard Sales Near You
        </Typography>
        
        <Stack spacing={2} alignItems="center">
          <form onSubmit={handleSearch} style={{ width: '100%', maxWidth: 600 }}>
            <Paper
              sx={{
                p: '8px 4px',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                borderRadius: 1
              }}
              elevation={0}
            >
              <InputAdornment position="start" sx={{ pl: 1 }}>
                <LocationOnIcon color="action" />
              </InputAdornment>
              <Box sx={{ flex: 1, ml: 1 }}>
                <LocationField
                  value={searchQuery}
                  onChange={handleLocationChange}
                />
              </Box>
              <IconButton 
                type="submit"
                sx={{ p: '10px' }} 
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <IconButton 
                sx={{ p: '10px' }} 
                aria-label="filters"
                onClick={handleFilterClick}
                type="button"
              >
                <TuneIcon />
              </IconButton>
            </Paper>
          </form>

          {(selectedCategories.length > 0 || distance !== DEFAULT_SEARCH_RADIUS || startDate || endDate) && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, maxWidth: 600 }}>
              {distance !== DEFAULT_SEARCH_RADIUS && (
                <Chip 
                  label={`Within ${distance} miles`}
                  onDelete={() => setDistance(DEFAULT_SEARCH_RADIUS)}
                  sx={{ bgcolor: 'white' }}
                />
              )}
              {startDate && (
                <Chip
                  label={`From ${formatDate(startDate)}`}
                  onDelete={() => setStartDate('')}
                  sx={{ bgcolor: 'white' }}
                />
              )}
              {endDate && (
                <Chip
                  label={`To ${formatDate(endDate)}`}
                  onDelete={() => setEndDate('')}
                  sx={{ bgcolor: 'white' }}
                />
              )}
              {selectedCategories.map((category) => (
                <Chip
                  key={category}
                  label={category}
                  onDelete={() => handleCategoryToggle(category)}
                  sx={{ bgcolor: 'white' }}
                />
              ))}
            </Box>
          )}

          <Button
            component={Link}
            to="/map"
            variant="contained"
            size="large"
            startIcon={<MapIcon />}
            sx={{ 
              bgcolor: 'white', 
              color: 'primary.main',
              '&:hover': {
                bgcolor: 'grey.100'
              },
              minWidth: 200,
              mt: 2
            }}
          >
            View Map
          </Button>
        </Stack>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box sx={{ p: 3, width: 300 }}>
            <Stack spacing={3}>
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Date Range
                </Typography>
                <Stack spacing={2}>
                  <DateRangePresets 
                    onSelect={(start, end) => {
                      setStartDate(start.toISOString().split('T')[0]);
                      setEndDate(end.toISOString().split('T')[0]);
                    }}
                  />
                  <Divider />
                  <Stack spacing={2}>
                    <TextField
                      label="Start Date"
                      type="date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      error={!!dateError}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: startOfToday().toISOString().split('T')[0],
                        max: addDays(new Date(), 90).toISOString().split('T')[0]
                      }}
                      fullWidth
                      size="small"
                    />
                    <TextField
                      label="End Date"
                      type="date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      error={!!dateError}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: startDate || startOfToday().toISOString().split('T')[0],
                        max: addDays(new Date(), 90).toISOString().split('T')[0]
                      }}
                      fullWidth
                      size="small"
                    />
                  </Stack>
                  {dateError && (
                    <Alert severity="error" sx={{ mt: 1 }}>
                      {dateError}
                    </Alert>
                  )}
                </Stack>
              </Box>

              <Divider />

              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Categories
                </Typography>
                <FormGroup>
                  {CATEGORIES.map((category) => (
                    <FormControlLabel
                      key={category}
                      control={
                        <Checkbox
                          checked={selectedCategories.includes(category)}
                          onChange={() => handleCategoryToggle(category)}
                          size="small"
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {category}
                        </Typography>
                      }
                    />
                  ))}
                </FormGroup>
              </Box>

              <Button
                variant="text"
                onClick={handleResetFilters}
                size="small"
              >
                Reset Filters
              </Button>
            </Stack>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

export default SearchBar;
