import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Stack,
  Button,
  Chip,
  Divider,
  Grid
} from '@mui/material';
import { 
  ReservableItem, 
  Reservation,
  getReservationStatusColor,
  getReservationStatusText,
  calculateDepositAmount
} from '../../types/reservation';

interface ReservationListProps {
  items: ReservableItem[];
  reservations: Reservation[];
  onApprove?: (reservationId: string) => void;
  onDeny?: (reservationId: string) => void;
  onReserve?: (itemId: string) => void;
  isOwner?: boolean;
}

const ReservationList: React.FC<ReservationListProps> = ({
  items,
  reservations,
  onApprove,
  onDeny,
  onReserve,
  isOwner = false
}) => {
  const getItemById = (itemId: string) => {
    return items.find(item => item.id === itemId);
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom>
        {isOwner ? 'Reservation Requests' : 'Reservable Items'}
      </Typography>

      {items.map(item => {
        const itemReservations = reservations.filter(r => r.itemId === item.id);
        const isReserved = itemReservations.some(r => r.status === 'approved');
        const depositAmount = calculateDepositAmount(item.price);

        return (
          <Paper key={item.id} sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Typography variant="subtitle1" gutterBottom>
                  {item.name}
                  {item.condition && (
                    <Chip
                      label={item.condition}
                      size="small"
                      sx={{ ml: 1 }}
                    />
                  )}
                </Typography>
                
                {item.description && (
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {item.description}
                  </Typography>
                )}

                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h6" color="primary">
                    ${item.price}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    (${depositAmount} deposit required)
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {isOwner ? (
                  <Stack spacing={1} width="100%">
                    {itemReservations.map(reservation => (
                      <Box key={reservation.id}>
                        <Chip
                          label={getReservationStatusText(reservation.status)}
                          sx={{
                            bgcolor: getReservationStatusColor(reservation.status),
                            color: 'white',
                            mb: 1
                          }}
                        />
                        {reservation.status === 'pending' && (
                          <Stack direction="row" spacing={1}>
                            <Button
                              size="small"
                              variant="contained"
                              color="success"
                              onClick={() => onApprove?.(reservation.id)}
                            >
                              Approve
                            </Button>
                            <Button
                              size="small"
                              variant="contained"
                              color="error"
                              onClick={() => onDeny?.(reservation.id)}
                            >
                              Deny
                            </Button>
                          </Stack>
                        )}
                      </Box>
                    ))}
                  </Stack>
                ) : (
                  <Button
                    variant="contained"
                    disabled={isReserved || !item.isReservable}
                    onClick={() => onReserve?.(item.id)}
                  >
                    {isReserved ? 'Reserved' : 'Reserve'}
                  </Button>
                )}
              </Grid>
            </Grid>

            {itemReservations.length > 0 && isOwner && (
              <>
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle2" gutterBottom>
                  Messages
                </Typography>
                <Stack spacing={1}>
                  {itemReservations.map(reservation => (
                    <Paper
                      key={reservation.id}
                      variant="outlined"
                      sx={{ p: 1 }}
                    >
                      <Typography variant="body2">
                        {reservation.message}
                      </Typography>
                      {reservation.sellerResponse && (
                        <>
                          <Divider sx={{ my: 1 }} />
                          <Typography variant="body2" color="text.secondary">
                            Your response: {reservation.sellerResponse}
                          </Typography>
                        </>
                      )}
                    </Paper>
                  ))}
                </Stack>
              </>
            )}
          </Paper>
        );
      })}
    </Stack>
  );
};

export default ReservationList;
