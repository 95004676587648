import React, { useEffect, useState } from 'react';
import { Container, Grid, CircularProgress, Alert } from '@mui/material';
import { useParams } from 'react-router-dom';
import { auth, getUserProfile } from '../services/firebase';
import { UserProfile } from '../types/user';
import PublicProfile from '../components/profile/PublicProfile';
import PrivateProfile from '../components/profile/PrivateProfile';
import { useAuthState } from 'react-firebase-hooks/auth';

const ProfilePage = () => {
  const { id } = useParams();
  const [user] = useAuthState(auth);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  const isOwnProfile = !id || (user && id === user.uid);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // If viewing own profile or no ID provided, use current user's ID
        const targetUserId = isOwnProfile ? user?.uid : id;
        
        if (!targetUserId) {
          setError('User not found');
          setLoading(false);
          return;
        }

        const userProfile = await getUserProfile(targetUserId);
        if (userProfile) {
          setProfile(userProfile);
        } else {
          setError('Profile not found');
        }
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError('Error loading profile');
      } finally {
        setLoading(false);
      }
    };

    if (user || id) {
      fetchProfile();
    }
  }, [user, id, isOwnProfile]);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 4, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error || !profile) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error">{error || 'Profile not found'}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          {isOwnProfile ? (
            <PrivateProfile profile={profile} />
          ) : (
            <PublicProfile profile={profile} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfilePage;
