import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { collection, query, orderBy, onSnapshot, doc, writeBatch, Timestamp } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { Report, AdminAction } from '../../types/admin';

const ReportsView = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState<AdminAction['action']>('delete_sale');
  const [actionReason, setActionReason] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Subscribe to reports collection
    const q = query(collection(db, 'reports'), orderBy('createdAt', 'desc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const reportsList: Report[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        reportsList.push({
          id: doc.id,
          ...data,
          createdAt: data.createdAt.toDate(),
          updatedAt: data.updatedAt.toDate()
        } as Report);
      });
      setReports(reportsList);
    });

    return () => unsubscribe();
  }, []);

  const handleAction = async () => {
    if (!selectedReport) return;

    try {
      setLoading(true);
      const batch = writeBatch(db);
      const now = new Date();

      // Create admin action
      const action: AdminAction = {
        id: doc(collection(db, 'adminActions')).id,
        adminId: 'admin', // TODO: Get actual admin ID
        action: selectedAction,
        targetId: selectedReport.saleId,
        reason: actionReason,
        timestamp: now.toISOString()
      };

      // Update report status
      const reportRef = doc(db, 'reports', selectedReport.id);
      batch.update(reportRef, {
        status: 'resolved',
        adminAction: action,
        updatedAt: Timestamp.fromDate(now)
      });

      // Handle different actions
      const saleRef = doc(db, 'sales', selectedReport.saleId);
      switch (selectedAction) {
        case 'delete_sale':
          batch.delete(saleRef);
          break;
        case 'ban_user':
          if (selectedReport.userId) {
            const userRef = doc(db, 'users', selectedReport.userId);
            batch.update(userRef, {
              status: 'banned',
              bannedAt: Timestamp.fromDate(now),
              banReason: actionReason
            });
            //batch.delete(userRef);
          }
          break;
        // Add other action handlers as needed
      }

      await batch.commit();
      setActionDialogOpen(false);
      setSelectedReport(null);
      setActionReason('');
    } catch (error) {
      console.error('Error handling report:', error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status: Report['status']) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'reviewed':
        return 'info';
      case 'resolved':
        return 'success';
      default:
        return 'default';
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Reports
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Sale ID</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Reporter</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report) => (
              <TableRow key={report.id}>
                <TableCell>
                  {report.createdAt.toLocaleDateString()}
                </TableCell>
                <TableCell>{report.saleId}</TableCell>
                <TableCell>{report.reason}</TableCell>
                <TableCell>
                  <Chip
                    label={report.status}
                    color={getStatusColor(report.status)}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  {report.userId || 'Anonymous'}
                </TableCell>
                <TableCell>
                  {report.status === 'pending' && (
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        color="error"
                        onClick={() => {
                          setSelectedReport(report);
                          setSelectedAction('delete_sale');
                          setActionDialogOpen(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        color="warning"
                        onClick={() => {
                          setSelectedReport(report);
                          setSelectedAction('ban_user');
                          setActionDialogOpen(true);
                        }}
                      >
                        <BlockIcon />
                      </IconButton>
                      <IconButton
                        color="success"
                        onClick={() => {
                          setSelectedReport(report);
                          setSelectedAction('feature_sale');
                          setActionDialogOpen(true);
                        }}
                      >
                        <CheckCircleIcon />
                      </IconButton>
                    </Stack>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={actionDialogOpen}
        onClose={() => setActionDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Take Action on Report
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 1 }}>
            <FormControl fullWidth>
              <InputLabel>Action</InputLabel>
              <Select
                value={selectedAction}
                onChange={(e) => setSelectedAction(e.target.value as AdminAction['action'])}
                label="Action"
              >
                <MenuItem value="delete_sale">Delete Sale</MenuItem>
                <MenuItem value="ban_user">Ban User</MenuItem>
                <MenuItem value="feature_sale">Mark as Featured</MenuItem>
                <MenuItem value="remove_review">Remove Review</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Reason"
              multiline
              rows={4}
              value={actionReason}
              onChange={(e) => setActionReason(e.target.value)}
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setActionDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleAction}
            variant="contained"
            color="primary"
            disabled={!actionReason || loading}
          >
            Confirm Action
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReportsView;
