import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Avatar,
  Stack,
  Chip,
  Grid,
  Rating
} from '@mui/material';
import { UserProfile } from '../../types/user';

interface PublicProfileProps {
  profile: UserProfile;
}

const PublicProfile: React.FC<PublicProfileProps> = ({ profile }) => {
  return (
    <Paper sx={{ p: 3 }}>
      <Stack spacing={3}>
        {/* Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Avatar
            src={profile.avatar}
            sx={{ width: 80, height: 80 }}
          >
            {profile.firstName[0]}{profile.lastName[0]}
          </Avatar>
          <Box>
            <Typography variant="h5">
              {profile.firstName} {profile.lastName}
              {profile.isVerified && (
                <Chip
                  label="Verified"
                  color="primary"
                  size="small"
                  sx={{ ml: 1 }}
                />
              )}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Member since {new Date(profile.joinDate).toLocaleDateString()}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {profile.location}
            </Typography>
          </Box>
        </Box>

        {/* Stats */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="text.secondary">
                Rating
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Rating value={profile.stats.averageRating} readOnly precision={0.5} />
                <Typography>
                  ({profile.stats.reviewCount} reviews)
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <Typography variant="subtitle2" color="text.secondary">
                Sales
              </Typography>
              <Typography>
                {profile.stats.completedSales} completed
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        {/* Social Stats */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">
              Followers
            </Typography>
            <Typography variant="h6">
              {profile.stats.followers}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">
              Following
            </Typography>
            <Typography variant="h6">
              {profile.stats.following}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};

export default PublicProfile;
