import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Rating,
  Typography,
  Alert
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { addReview } from '../../services/firebase';

interface RatingDialogProps {
  open: boolean;
  onClose: () => void;
  saleId: string;
  userId: string;
  onRated?: () => void;
}

const labels: { [index: string]: string } = {
  1: 'Poor',
  2: 'Fair',
  3: 'Good',
  4: 'Very Good',
  5: 'Excellent',
};

const RatingDialog: React.FC<RatingDialogProps> = ({
  open,
  onClose,
  saleId,
  userId,
  onRated
}) => {
  const [rating, setRating] = useState<number | null>(null);
  const [hover, setHover] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    if (!rating) return;

    try {
      setLoading(true);
      setError('');
      await addReview(saleId, userId, rating);
      onRated?.();
      onClose();
    } catch (err) {
      console.error('Error submitting rating:', err);
      setError('Failed to submit rating. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Rate this Yard Sale</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            py: 2
          }}
        >
          <Rating
            size="large"
            value={rating}
            onChange={(_, newValue) => {
              setRating(newValue);
            }}
            onChangeActive={(_, newHover) => {
              setHover(newHover);
            }}
            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          />
          {rating !== null && (
            <Typography variant="body2" color="text.secondary">
              {labels[hover !== -1 ? hover : rating]}
            </Typography>
          )}
          {error && (
            <Alert severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!rating || loading}
        >
          {loading ? 'Submitting...' : 'Submit Rating'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RatingDialog;
