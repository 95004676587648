import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  useMediaQuery,
  useTheme,
  Drawer,
  IconButton,
  CircularProgress,
  Alert,
  Snackbar,
  Autocomplete,
  TextField
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useSearchParams } from 'react-router-dom';
import YardSaleMap from '../components/map/YardSaleMap';
import YardSaleList from '../components/map/YardSaleList';
import MapPageFilters from '../components/map/MapPageFilters';
import { getYardSaleListings } from '../services/firebase';
import { getLocationSuggestions, LocationSuggestion } from '../services/mapbox';
import { UserSale } from '../types/user';

interface Location {
  lat: number;
  lng: number;
}

const MapPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams] = useSearchParams();
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [sales, setSales] = useState<UserSale[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedSale, setSelectedSale] = useState<UserSale | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const [center, setCenter] = useState<Location | null>(null);
  const [locationError, setLocationError] = useState('');
  const [gettingLocation, setGettingLocation] = useState(true);
  const [hasSearched, setHasSearched] = useState(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  
  // Search state
  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState<LocationSuggestion[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);

  // Fallback center (Boise, ID) if geolocation fails
  const fallbackCenter = {
    lat: 43.6150,
    lng: -116.2023
  };

  // Handle URL parameters
  useEffect(() => {
    const location = searchParams.get('location');
    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');
    const categoryParam = searchParams.get('categories');
    const startDateParam = searchParams.get('startDate');
    const endDateParam = searchParams.get('endDate');

    console.log('URL params:', { location, lat, lng }); // Debug log

    if (location) {
      setSearchValue(location);
    }

    if (lat && lng) {
      const newCenter = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      };
  
        setCenter(newCenter);
        setHasSearched(true);
    }

    if (categoryParam) {
      setCategories(categoryParam.split(','));
    }

    if (startDateParam) {
      setStartDate(startDateParam);
    }

    if (endDateParam) {
      setEndDate(endDateParam);
    }
  }, [searchParams]);

  // Handle location search
  const handleSearch = async (value: string) => {
    if (!value) {
      setSuggestions([]);
      return;
    }
    
    setSearchLoading(true);
    try {
      const results = await getLocationSuggestions(value);
      setSuggestions(results);
    } catch (error) {
      console.error('Error getting suggestions:', error);
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    const getUserLocation = () => {
      if (!navigator.geolocation) {
        setLocationError('Geolocation is not supported by your browser');
        setCenter(fallbackCenter);
        setGettingLocation(false);
        return;
      }
  
      console.log('Requesting geolocation...');
  
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('Got position:', position.coords);
          const newCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          console.log('Setting center to:', newCenter);
          if (!hasSearched) {
            setCenter(newCenter);
          }
          setGettingLocation(false);
        },
        (error) => {
          console.error('Geolocation error:', error);
          setLocationError(`Unable to get your location: ${error.message}`);
          if (!hasSearched) {
            setCenter(fallbackCenter);
          }
          setGettingLocation(false);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    };
  
    getUserLocation();
  }, [hasSearched]);

  const fetchListings = async (newCenter: Location, locationText?: string) => {
    try {
      setLoading(true);
      const response = await getYardSaleListings({
        categories,
        limit: 50,
        location: locationText || searchValue, // Use location text for filtering
        coordinates: newCenter, // Pass coordinates separately for map display
        startDate,
        endDate
      });
      setSales(response.listings);
    } catch (error) {
      console.error('Error fetching listings:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (center && hasSearched) {
      fetchListings(center);
    }
  }, [categories, center, startDate, endDate, hasSearched]);

  const handleReset = () => {
    setCategories([]);
    setStartDate('');
    setEndDate('');
  };

  const drawerContent = (
    <Box sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <MapPageFilters
        categories={categories}
        onCategoryChange={setCategories}
        onReset={handleReset}
      />
      <Box sx={{ flex: 1, overflow: 'auto', mt: 2 }}>
        <YardSaleList
          sales={hasSearched ? sales : []}
          loading={loading}
          selectedSaleId={selectedSale?.id}
          onSaleSelect={setSelectedSale}
        />
      </Box>
    </Box>
  );

  if (gettingLocation) {
    return (
      <Box 
        sx={{ 
          height: '100vh', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <CircularProgress />
        <Box>Getting your location...</Box>
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {isMobile && (
        <Box sx={{ p: 1, bgcolor: 'background.paper' }}>
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
            <MenuIcon />
          </IconButton>
        </Box>
      )}

      <Box sx={{ flex: 1, display: 'flex', overflow: 'hidden', position: 'relative' }}>
        {/* Search bar */}
        <Box 
          sx={{ 
            position: 'absolute',
            top: 16,
            left: isMobile ? 16 : 366,
            right: 16,
            zIndex: 2
          }}
        >
          <Autocomplete
            freeSolo
            loading={searchLoading}
            options={suggestions}
            getOptionLabel={(option) => 
              typeof option === 'string' ? option : option.context
            }
            filterOptions={(x) => x}
            onChange={async (_, value) => {
              if (value && typeof value !== 'string') {
                // Clear existing results
                setSales([]);
                setSelectedSale(null);
                
                // Set new center and fetch new results
                setCenter(value.location);
                setSearchValue(value.context); // Set the location text
                setHasSearched(true);
                await fetchListings(value.location, value.context);
              }
            }}
            onInputChange={(_, newValue) => {
              setSearchValue(newValue);
              handleSearch(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="Search Locations..."
                helperText="Start Typing and Select from Suggestions."
                FormHelperTextProps={{
                  sx: { 
                    color: 'text.secondary',
                    position: 'absolute',
                    bottom: -20
                  }
                }}
                sx={{ 
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  '& .MuiOutlinedInput-root': {
                    border: '2px solid rgba(0, 0, 0, 0.23)',
                    '& fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                      borderWidth: '1px',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(0, 0, 255, 0.5)',// Blue on hover
                      borderWidth: '2px',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'primary.main',
                      borderWidth: '2px',
                    }
                  },
                }}
              />
            )}
          />
        </Box>

        {isMobile ? (
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            sx={{
              width: 350,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: 350,
              },
            }}
          >
            {drawerContent}
          </Drawer>
        ) : (
          <Box
            sx={{
              width: 350,
              flexShrink: 0,
              bgcolor: 'background.default',
              overflow: 'auto'
            }}
          >
            {drawerContent}
          </Box>
        )}

        <Box sx={{ flex: 1, position: 'relative' }}>
          <YardSaleMap
            center={center || fallbackCenter}
            sales={hasSearched ? sales : []}
            selectedSale={selectedSale}
            onSaleSelect={setSelectedSale}
          />
        </Box>
      </Box>

      <Snackbar 
        open={!!locationError} 
        autoHideDuration={6000} 
        onClose={() => setLocationError('')}
      >
        <Alert severity="warning" onClose={() => setLocationError('')}>
          {locationError}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MapPage;
