import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Chip,
  SelectChangeEvent,
  Divider,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReservableItemForm from '../components/reservation/ReservableItemForm';
import LocationField from '../components/LocationField';
import ImageUpload from '../components/ImageUpload';
import { ReservableItem } from '../types/reservation';
import { ParkingType, Coordinates, UserSale } from '../types/user';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import NearMeIcon from '@mui/icons-material/NearMe';
import HomeIcon from '@mui/icons-material/Home';
import BlockIcon from '@mui/icons-material/Block';
import { auth, createYardSale } from '../services/firebase';
import { uploadImages } from '../services/storage';
import { useAuthState } from 'react-firebase-hooks/auth';

const CreateSalePage = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [location, setLocation] = useState('');
  const [coordinates, setCoordinates] = useState<Coordinates | undefined>();
  const [categories, setCategories] = useState<string[]>([]);
  const [reservableItems, setReservableItems] = useState<ReservableItem[]>([]);
  const [parkingType, setParkingType] = useState<ParkingType>('street');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [images, setImages] = useState<string[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [uploadError, setUploadError] = useState('');

  const handleLocationChange = (address: string, coords?: Coordinates) => {
    setLocation(address);
    setCoordinates(coords);
  };

  const handleImagesChange = (newImages: string[], newFiles?: FileList) => {
    setImages(newImages);
    if (newFiles) {
      setImageFiles(Array.from(newFiles));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      setError('You must be signed in to create a sale');
      return;
    }

    if (!coordinates) {
      setError('Please enter a valid address');
      return;
    }

    try {
      setLoading(true);
      setError('');
      setUploadError('');

      // Upload images if any
      let imageUrls: string[] = [];
      if (imageFiles.length > 0) {
        try {
          imageUrls = await uploadImages(user.uid, imageFiles);
        } catch (err) {
          setUploadError('Failed to upload images. Please try again.');
          return;
        }
      }

      const saleData: Partial<UserSale> = {
        title,
        description,
        date,
        time: {
          start: startTime,
          end: endTime
        },
        location,
        coordinates,
        categories,
        parkingType,
        status: 'upcoming',
        images: imageUrls
      };

      await createYardSale(user.uid, saleData);
      navigate('/profile');
    } catch (err) {
      console.error('Error creating yard sale:', err);
      setError('Failed to create yard sale. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent<string[]>) => {
    setCategories(event.target.value as string[]);
  };

  const handleAddItem = (item: ReservableItem) => {
    setReservableItems([...reservableItems, item]);
  };

  const handleRemoveItem = (itemId: string) => {
    setReservableItems(reservableItems.filter(item => item.id !== itemId));
  };

  const handleUpdateItem = (itemId: string, updates: Partial<ReservableItem>) => {
    setReservableItems(reservableItems.map(item => 
      item.id === itemId ? { ...item, ...updates } : item
    ));
  };

  // Get tomorrow's date as the min date for the date picker
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const minDate = tomorrow.toISOString().split('T')[0];

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Create a Yard Sale
        </Typography>

        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              fullWidth
            />

            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={4}
              required
              fullWidth
            />

            <TextField
              label="Date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: minDate }}
            />

            <Stack direction="row" spacing={2}>
              <TextField
                label="Start Time"
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                label="End Time"
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Stack>

            <LocationField
                value={location}
                onChange={handleLocationChange}
                required
                error={!coordinates && location ? "Please Type the Full Address and Select a Location from the Dropdown Suggestions." : undefined}
              />

            <Box>
              <Typography variant="h6" gutterBottom>
                Sale Images
              </Typography>
              <ImageUpload
                images={images}
                onImagesChange={handleImagesChange}
                maxImages={10}
                loading={loading}
                error={uploadError}
              />
            </Box>

            <FormControl>
              <FormLabel>Parking Options</FormLabel>
              <RadioGroup
                value={parkingType}
                onChange={(e) => setParkingType(e.target.value as ParkingType)}
              >
                <FormControlLabel
                  value="street"
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <DirectionsCarIcon color="info" />
                      <span>Street Parking Available</span>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="nearby"
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <NearMeIcon color="info" />
                      <span>Nearby Parking Available</span>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="driveway"
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <HomeIcon color="success" />
                      <span>Driveway Parking Available</span>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="none"
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <BlockIcon color="disabled" />
                      <span>No Parking Available</span>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Categories</InputLabel>
              <Select
                multiple
                value={categories}
                onChange={handleCategoryChange}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value="Antiques & Collectibles">Antiques & Collectibles</MenuItem>
                <MenuItem value="Art & Craft Supplies">Art & Craft Supplies</MenuItem>
                <MenuItem value="Automotive">Automotive</MenuItem>
                <MenuItem value="Baby & Kids">Baby & Kids</MenuItem>
                <MenuItem value="Books">Books</MenuItem>
                <MenuItem value="Clothing">Clothing</MenuItem>
                <MenuItem value="Electronics">Electronics</MenuItem>
                <MenuItem value="Furniture">Furniture</MenuItem>
                <MenuItem value="Gardening">Gardening</MenuItem>
                <MenuItem value="Health & Beauty">Health & Beauty</MenuItem>
                <MenuItem value="Home Decor">Home Decor</MenuItem>
                <MenuItem value="Jewelry & Accessories">Jewelry & Accessories</MenuItem>
                <MenuItem value="Kitchen & Dining">Kitchen & Dining</MenuItem>
                <MenuItem value="Music & Instruments">Music & Instruments</MenuItem>
                <MenuItem value="Office Supplies">Office Supplies</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
                <MenuItem value="Pet Supplies">Pet Supplies</MenuItem>
                <MenuItem value="Seasonal & Holiday Items">Seasonal & Holiday Items</MenuItem>
                <MenuItem value="Sports & Outdoor Equipment">Sports & Outdoor Equipment</MenuItem>
                <MenuItem value="Tools">Tools</MenuItem>
                <MenuItem value="Toys">Toys</MenuItem>
                <MenuItem value="Video Games">Video Games</MenuItem>
              </Select>
            </FormControl>

            <Divider />

            <ReservableItemForm
              items={reservableItems}
              onAddItem={handleAddItem}
              onRemoveItem={handleRemoveItem}
              onUpdateItem={handleUpdateItem}
            />

            {error && (
              <Alert severity="error">
                {error}
              </Alert>
            )}

            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={loading || !coordinates}
              sx={{ mt: 2 }}
            >
              {loading ? 'Creating...' : 'Create Sale'}
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateSalePage;
