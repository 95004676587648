import React from 'react';
import { Box } from '@mui/material';
import SearchBar from '../components/SearchBar';
import FeaturedSales from '../components/FeaturedSales';

const HomePage = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <SearchBar />
      <Box sx={{ p: 3 }}>
        <FeaturedSales />
      </Box>
    </Box>
  );
};

export default HomePage;
