import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Container,
  Chip,
  CircularProgress,
  Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ReviewStars from './social/ReviewStars';
import { db } from '../services/firebase';
import { collection, query, where, limit, getDocs } from 'firebase/firestore';
import { UserSale } from '../types/user';
import { categoryColors } from '../config/constants';

const FeaturedSales = () => {
  const navigate = useNavigate();
  const [sales, setSales] = useState<UserSale[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchFeaturedSales = async () => {
      try {
        setLoading(true);
        setError('');

        // Query for featured sales that are active or upcoming
        const q = query(
          collection(db, 'sales'),
          where('featured', '==', true),
          where('status', 'in', ['active', 'upcoming']),
          limit(3)
        );

        const querySnapshot = await getDocs(q);
        const featuredSales = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          createdAt: doc.data().createdAt?.toDate(),
          updatedAt: doc.data().updatedAt?.toDate()
        })) as UserSale[];

        setSales(featuredSales);
      } catch (err) {
        console.error('Error fetching featured sales:', err);
        setError('Failed to load featured sales');
      } finally {
        setLoading(false);
      }
    };

    fetchFeaturedSales();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ py: 8 }}>
        <Container maxWidth="lg">
          <Alert severity="error">{error}</Alert>
        </Container>
      </Box>
    );
  }

  if (sales.length === 0) {
    return null;
  }

  return (
    <Box sx={{ py: 6, backgroundColor: 'background.default' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
          Featured Yard Sales This Week
        </Typography>

        <Grid container spacing={4}>
          {sales.map((sale) => (
            <Grid item xs={12} md={4} key={sale.id}>
              <Card 
                sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column',
                  backgroundColor: 'background.paper',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    transition: 'transform 0.2s ease-in-out'
                  }
                }}
              >
                <Box
                  sx={{
                    height: 200,
                    background: sale.images && sale.images.length > 0 
                      ? `url(${sale.images[0]})` 
                      : 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    transition: 'opacity 0.2s ease-in-out',
                    '&:hover': {
                      opacity: 0.9
                    }
                  }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="h2">
                    {sale.title}
                  </Typography>

                  {sale.stats && (
                    <Box sx={{ mb: 2 }}>
                      <ReviewStars 
                        value={0} // TODO: Add rating to sale type
                        showCount 
                        count={sale.stats.saves}
                        size="small"
                      />
                    </Box>
                  )}

                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <LocationOnIcon sx={{ fontSize: 20, mr: 1, color: 'text.secondary' }} />
                    <Typography variant="body2" color="text.secondary">
                      {sale.location}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CalendarTodayIcon sx={{ fontSize: 20, mr: 1, color: 'text.secondary' }} />
                    <Typography variant="body2" color="text.secondary">
                      {new Date(sale.date).toLocaleDateString()}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <AccessTimeIcon sx={{ fontSize: 20, mr: 1, color: 'text.secondary' }} />
                    <Typography variant="body2" color="text.secondary">
                      {sale.time.start} - {sale.time.end}
                    </Typography>
                  </Box>

                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                      mb: 2,
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3,
                    }}
                  >
                    {sale.description}
                  </Typography>

                  <Box sx={{ mb: 2 }}>
                    {sale.categories.map((category) => (
                      <Chip
                        key={category}
                        label={category}
                        size="small"
                        sx={{
                          mr: 0.5,
                          mb: 0.5,
                          backgroundColor: categoryColors[category] || '#868e96',
                          color: 'white',
                        }}
                      />
                    ))}
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <Button 
                      variant="contained" 
                      fullWidth
                      onClick={() => navigate(`/sale/${sale.id}`)}
                    >
                      View Details
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FeaturedSales;
