import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  IconButton,
  Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReservableItem, calculateDepositAmount } from '../../types/reservation';

interface ReservationDialogProps {
  open: boolean;
  onClose: () => void;
  item: ReservableItem;
  onSubmit: (message: string) => void;
}

export const ReservationDialog: React.FC<ReservationDialogProps> = ({
  open,
  onClose,
  item,
  onSubmit
}) => {
  const [message, setMessage] = useState('');
  const depositAmount = calculateDepositAmount(item.price);

  const handleSubmit = () => {
    onSubmit(message);
    setMessage('');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Reserve Item
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            {item.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Price: ${item.price}
          </Typography>
          <Alert severity="info" sx={{ mt: 2 }}>
            A ${depositAmount} deposit is required to reserve this item. 
            The deposit will be refunded if the seller denies your reservation 
            or if you cancel at least 24 hours before the sale.
          </Alert>
        </Box>

        <TextField
          label="Message to Seller"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
          placeholder="Introduce yourself and let the seller know why you're interested in this item."
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!message.trim()}
        >
          Reserve (${depositAmount} deposit)
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReservationDialog;
