import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  IconButton,
  Typography,
  Alert,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { auth, db } from '../services/firebase';
import { 
  collection, 
  addDoc, 
  Timestamp
} from 'firebase/firestore';
import { Report } from '../types/admin';

interface ReportDialogProps {
  open: boolean;
  onClose: () => void;
  saleId: string;
}

const REPORT_REASONS = [
  'Inappropriate content',
  'Misleading information',
  'Spam',
  'Scam',
  'Other'
];

const ReportDialog: React.FC<ReportDialogProps> = ({
  open,
  onClose,
  saleId
}) => {
  const [reason, setReason] = useState('');
  const [details, setDetails] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    if (!reason) {
      setError('Please select a reason for reporting');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      
      const now = new Date();
      const reportData: Omit<Report, 'id'> = {
        saleId,
        userId: auth.currentUser?.uid || null,
        reason,
        details: details.trim(),
        status: 'pending',
        createdAt: now,
        updatedAt: now
      };
      
      await addDoc(collection(db, 'reports'), {
        ...reportData,
        createdAt: Timestamp.fromDate(now),
        updatedAt: Timestamp.fromDate(now)
      });

      setSubmitted(true);
    } catch (err) {
      console.error('Error submitting report:', err);
      if (err instanceof Error) {
        setError(`Failed to submit report: ${err.message}`);
      } else {
        setError('Failed to submit report. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (!loading) {
      setReason('');
      setDetails('');
      setSubmitted(false);
      setError(null);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown={loading}
    >
      <DialogTitle>
        Report Yard Sale
        {!loading && (
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {submitted ? (
          <Alert severity="success" sx={{ mt: 2 }}>
            Thank you for your report. Our team will review it shortly.
          </Alert>
        ) : (
          <Stack spacing={3} sx={{ mt: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Please help us understand what's wrong with this yard sale listing.
              Your report will be reviewed by our team.
              {!auth.currentUser && (
                <Typography variant="body2" color="warning.main" sx={{ mt: 1 }}>
                  Note: You are reporting anonymously. Creating an account helps us better handle reports.
                </Typography>
              )}
            </Typography>

            {error && (
              <Alert severity="error">
                {error}
              </Alert>
            )}

            <FormControl>
              <FormLabel>Reason for reporting</FormLabel>
              <RadioGroup
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              >
                {REPORT_REASONS.map((r) => (
                  <FormControlLabel
                    key={r}
                    value={r}
                    control={<Radio />}
                    label={r}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <TextField
              label="Additional Details"
              multiline
              rows={4}
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              placeholder="Please provide any additional information that will help us understand the issue."
              fullWidth
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          {submitted ? 'Close' : 'Cancel'}
        </Button>
        {!submitted && (
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={!reason || loading}
            startIcon={loading && <CircularProgress size={20} />}
          >
            Submit Report
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ReportDialog;
