export type ReservationStatus = 'pending' | 'approved' | 'denied' | 'cancelled';

export interface ReservableItem {
  id: string;
  name: string;
  price: number;
  description?: string;
  image?: string;
  category?: string;
  condition?: string;
  isReservable: boolean;
}

export interface Reservation {
  id: string;
  itemId: string;
  saleId: string;
  buyerId: string;
  sellerId: string;
  status: ReservationStatus;
  createdAt: string;
  updatedAt: string;
  depositAmount: number;
  message?: string;
  sellerResponse?: string;
}

// Helper functions
export const calculateDepositAmount = (price: number): number => {
  // 10% deposit, minimum $5, maximum $50
  const deposit = price * 0.1;
  return Math.min(Math.max(deposit, 5), 50);
};

export const getReservationStatusColor = (status: ReservationStatus): string => {
  switch (status) {
    case 'approved':
      return '#4caf50'; // green
    case 'denied':
      return '#f44336'; // red
    case 'cancelled':
      return '#9e9e9e'; // grey
    default:
      return '#ff9800'; // orange for pending
  }
};

export const getReservationStatusText = (status: ReservationStatus): string => {
  switch (status) {
    case 'approved':
      return 'Reservation Approved';
    case 'denied':
      return 'Reservation Denied';
    case 'cancelled':
      return 'Reservation Cancelled';
    default:
      return 'Pending Approval';
  }
};
